import React, { useState, useEffect, useContext } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import {
  Button,
  Dropdown,
  DropdownItem,
  ErrorDescriptor,
  ICompany,
  Input,
  StepComponent,
} from '@gsp/gusto-front-common'

import BondContext from '../../../../models/BondContext'

import IQuotationSharedContext from '../../../../models/IQuotationSharedContext'

import { fetchCompany } from '../../../../services/company'

import './CompanySearch.scss'
import CompanySearchResult from './CompanySearchResult/CompanySearchResult'

const CompanySearch = ({ setStepCompleted }: StepComponent<IQuotationSharedContext>) => {
  const [identifierType, setIdentifierType] = useState('COFIS')
  const [identifier, setIdentifier] = useState('')
  const { bond, setBond } = useContext(BondContext)
  const [company, setCompany] = useState<ICompany | null>(null)
  const [loading, setLoading] = useState(false)
  const [errorDescriptor, setErrorDescriptor] = useState<ErrorDescriptor | undefined>()

  const searchCompany = () => {
    const searchedIdentifier = identifier
    setLoading(true)

    fetchCompany('IT', identifierType, identifier)
      .then(fetchedCompany => {
        const fetchedCompanyWithID = {
          ...fetchedCompany,
          identifier: searchedIdentifier,
          identifierType,
          irpCompanyId: fetchedCompany.identifier,
        }
        setCompany(fetchedCompanyWithID)
        setBond({ ...bond, company: fetchedCompanyWithID })
        setErrorDescriptor(undefined)
        setLoading(false)
      })
      .catch(descriptor => {
        if (descriptor) {
          setErrorDescriptor(descriptor as ErrorDescriptor)
        }
        setLoading(false)
      })
  }

  useEffect(() => setStepCompleted(company !== null), [company, setStepCompleted])

  const companiesIdTypes: DropdownItem[] = [
    {
      code: 'COFIS',
      labelKey: 'fiscal-code',
    },
    {
      code: 'TVAIT',
      labelKey: 'vat-code',
    },
  ]

  return (
    <div data-testid="company-search" className="company-search-container">
      <Container>
        <Row>
          <Col>
            <Dropdown
              dataTestid="company-identifier"
              labelKey="company-identifier"
              items={companiesIdTypes}
              value={identifierType}
              onChange={setIdentifierType}
            />
            <Input
              dataTestid="company-code"
              labelKey="company-code"
              value={identifier}
              changeValue={setIdentifier}
              isRequired={true}
              forceUppercase
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              className="company-search-find-button"
              dataTestid="find-my-company"
              disabled={!identifier}
              onClick={searchCompany}
              i18nKey="find-my-company"
            />
          </Col>
        </Row>
        <Row>
          <div className="company-search-result">
            <CompanySearchResult loading={loading} errorDescriptor={errorDescriptor} company={company} />
          </div>
        </Row>
      </Container>
    </div>
  )
}
export default CompanySearch
