import React, { useContext, useState } from 'react'
import { Col, Row, Container } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { Link, Navigate, Route, Routes } from 'react-router-dom'
import BondRequest from './BondRequest/BondRequest'
import BondContext from '../../models/BondContext'
import Header from './Header/Header'
import Footer from './Footer/Footer'
import QuotationsList from './QuotationsList/QuotationsList'
import './Dashboard.scss'

import portfolioIcon from '../../resources/quotation-list-portfolio-icon.svg'
import makeIcon from '../../resources/quotation-list-make-icon.svg'

interface IntermediaryDashboardProps {
  ready: boolean
}
export const IntermediaryDashboard = ({ ready }: IntermediaryDashboardProps) => {
  const { bond } = useContext(BondContext)
  const [pathKey, setPathKey] = useState(0)

  // Work around to force BondRequest to update
  const handleNewQuoteClick = () => {
    setPathKey(pathKey + 1)
  }

  return (
    <>
      <Header />
      <Container fluid data-testid="intermediary" className="dashboard">
        {ready && (
          <Row>
            <Col className="dashboard-navbar pr-0 print--hidden" md={2}>
              <Link to={`/dashboard${window.location.search}`}>
                <div className="dashboard-navbar-option">
                  <img src={portfolioIcon} className="dashboard-icon" alt="Go to my portfolio" />
                  <FormattedMessage id="dashboard-quotation-list" />
                </div>
              </Link>
              <Link to={`/new-quote${window.location.search}`} onClick={handleNewQuoteClick}>
                <div className="dashboard-navbar-option">
                  <img src={makeIcon} className="dashboard-icon" alt="Make quotation" />
                  <FormattedMessage id="dashboard-make-quote" />
                </div>
              </Link>
            </Col>
            <Col className="dashboard-content print-w100 pr-0" md={10}>
              <div className="dashboard-content-box">
                <Routes>
                  <Route
                    path="/new-quote"
                    key={pathKey}
                    element={<BondRequest createNewBond={true} currentStage={0} />}
                  />
                  <Route
                    path="/bond-request"
                    element={
                      // Forbid access to the BondRequest process if no quotation has been selected
                      bond.bondQuotationRequestId ? (
                        <BondRequest currentStage={1} />
                      ) : (
                        <Navigate to="/dashboard" replace />
                      )
                    }
                  />
                  <Route
                    path="/bond-request/summary"
                    element={
                      // Forbid access to the BondRequest process if no quotation has been selected
                      bond.bondQuotationRequestId ? (
                        <BondRequest currentStage={1} currentStepIndex={3} />
                      ) : (
                        <Navigate to="/dashboard" replace />
                      )
                    }
                  />
                  <Route path="/dashboard" element={<QuotationsList />} />
                  <Route path="/" element={<Navigate to="/dashboard" replace />} />
                </Routes>
                <Footer />
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </>
  )
}

export default IntermediaryDashboard
