export interface ProjectInformationsParameters {
  editableDescription: boolean
  defaultDescription: string
  displayTooltip?: boolean
}

const projectInformationsParameters: { [key: string]: ProjectInformationsParameters } = {
  BID: {
    editableDescription: true,
    defaultDescription: '',
  },
  PERFORMANCE: {
    editableDescription: true,
    defaultDescription: '',
  },
  LIS: {
    editableDescription: false,
    defaultDescription:
      'GARANZIA DELL’ESATTO E PUNTUALE ADEMPIMENTO DELLE OBBLIGAZIONI DERIVANTI DAL CONTRATTO STIPULATO CON LIS IP, CARTA LIS IMEL E LIS SPA PER LA FORNITURA DI SERVIZI COMMERCIALI, D’INCASSO FONDI, UTILIZZO TERMINALE, DISTRIBUZIONE DI MONETE ELETTRONICA, ACQUIRING E ACCESSORI.',
  },
  UNA_TANTUM: {
    editableDescription: true,
    defaultDescription: 'RIVENDITA DI GENERI DI MONOPOLIO N.XXX - CITTÀ - CON ANNESSA RICEVITORIA N.XXX',
    displayTooltip: true,
  },
  TAX_STAMPS: {
    editableDescription: false,
    defaultDescription:
      'FIDEIUSSIONE ASSICURATIVA PRESTATA IN FORMA SINGOLA PER SVOLGERE IL SERVIZIO REGOLATO DALLA CONVENZIONE SULLA RISCOSSIONE IN VIA TELEMATICA DELL’IMPOSTA DI BOLLO, DEL CONTRIBUTO UNIFICATO E DI ALTRI TRIBUTI MEDIANTE RILASCIO DI CONTRASSEGNO SOSTITUTIVO',
  },
  LOTTERY: {
    editableDescription: false,
    defaultDescription: 'FIDEIUSSIONE PUNTI VENDITA LOTTERIE',
  },
  LOTTO: {
    editableDescription: true,
    defaultDescription: 'RICEVITORIA LOTTO N.XXX',
    displayTooltip: true,
  },
  NINE_YEAR: {
    editableDescription: true,
    defaultDescription: 'RIVENDITA DI GENERI DI MONOPOLIO N.XXXX',
    displayTooltip: true,
  },
}

export default projectInformationsParameters
