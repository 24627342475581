import React, { useContext, useEffect, useState } from 'react'
import { IStep, Stepper, Wizard } from '@gsp/gusto-front-common'
import BondContext, { initBond } from '../../../models/BondContext'
import CompanySearch from './CompanySearch/CompanySearch'
import UserType from './UserType/UserType'
import QuotationType from './QuotationType/QuotationType'
import QuotationDetail from './QuotationDetail/QuotationDetail'
import QuotationRequest from './QuotationRequest/QuotationRequest'
import ClientInformation from './ClientInformation/ClientInformation'
import BeneficiaryInformation from './BeneficiaryInformation/BeneficiaryInformation'
import ProjectInformation from './ProjectInformation/ProjectInformation'
import AdditionalInformation from './AdditionalInformation/AdditionalInformation'
import Summary from './Summary/Summary'
import BondRequestStep from './BondRequestStep/BondRequestStep'
import IQuotationSharedContext from '../../../models/IQuotationSharedContext'
import './BondRequest.scss'
import { isBondFinalized } from '../../../utils/bondUtils'

interface BondWizardProps {
  currentStage: number
  currentStepIndex?: number
  createNewBond?: boolean
}

const wizardSteps = [
  {
    key: 'wizard-step-quote',
  },
  {
    key: 'wizard-step-subscribe',
  },
]

const quotationSteps: IStep<IQuotationSharedContext>[] = [
  {
    component: CompanySearch,
    id: 1,
    labelKey: 'find-my-company',
  },
  {
    component: UserType,
    id: 2,
    labelKey: 'intermediary-user-type',
    customControls: true,
  },
  {
    component: QuotationType,
    id: 3,
    labelKey: 'intermediary-step-type',
    customControls: true,
  },
  {
    component: QuotationDetail,
    id: 4,
    labelKey: 'intermediary-step-estimation',
  },
  {
    component: QuotationRequest,
    id: 5,
    labelKey: 'intermediary-step-subscription',
    noReturn: true,
  },
]

const clientInformationStep = {
  component: ClientInformation,
  id: 1,
  labelKey: 'client-information',
} as IStep

const beneficiaryInformationStep = {
  component: BeneficiaryInformation,
  id: 2,
  labelKey: 'beneficiary-company',
}

const projectInformationStep = {
  component: ProjectInformation,
  id: 3,
  labelKey: 'beneficiary-project',
}

const projectTobacconistInformationStep = {
  ...projectInformationStep,
  labelKey: 'beneficiary-project-tobacco',
}

const additionalInformationStep = {
  component: AdditionalInformation,
  id: 4,
  labelKey: 'beneficiary-additional',
}

const summaryStep = {
  component: Summary,
  id: 5,
  labelKey: 'beneficiary-summary',
  customControls: true,
}

const bondRequestStep = {
  component: BondRequestStep,
  id: 6,
  labelKey: 'bond-request-result',
  noReturn: true,
}

const tenderBondRequestSteps: IStep[] = [
  beneficiaryInformationStep,
  projectInformationStep,
  additionalInformationStep,
  summaryStep,
  bondRequestStep,
]

const tobacconistBondRequestSteps: IStep[] = [
  clientInformationStep,
  beneficiaryInformationStep,
  projectTobacconistInformationStep,
  summaryStep,
  bondRequestStep,
]

const BondRequest = ({ currentStage, currentStepIndex = 0, createNewBond = false }: BondWizardProps) => {
  const [wizardStage, setWizardStage] = useState(currentStage)
  const { bond, setBond } = useContext(BondContext)

  const isFinalizedBond = isBondFinalized(bond)

  useEffect(() => {
    if (createNewBond) {
      setBond(initBond('BID'))
    }

    if (
      process.env.REACT_APP_TOBACCONIST_ENABLED === 'false' ||
      new URLSearchParams(window.location.search).get('tobacconistEnabled') === 'false'
    ) {
      if (quotationSteps.find(elem => elem.virtualPageId === '/intermediaries/user-type')) {
        quotationSteps.splice(1, 1)
      }
      setBond({
        ...bond,
        intermediaryType: 'TENDERS',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const bondRequestSteps = bond.intermediaryType === 'TOBACCO' ? tobacconistBondRequestSteps : tenderBondRequestSteps

  const steps = wizardStage === 0 ? quotationSteps : bondRequestSteps

  return (
    <div className="bond-wizard">
      <Wizard hidden={isFinalizedBond} wizardStage={wizardStage} wizardSteps={wizardSteps} />
      <Stepper
        hidden={isFinalizedBond}
        steps={steps}
        initialStep={currentStepIndex}
        sharedContext={{
          goToNextStage: () => {
            setWizardStage(1)
          },
        }}
        key={wizardStage}
      />
    </div>
  )
}

export default BondRequest
