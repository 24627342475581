import { IDuration } from '@gsp/gusto-front-common'
import { ReactNode } from 'react'

const defaultBidDurationDays = 180
const bidBondMinDurationInDays = 1
export const bidBondMaxDurationInDays = 240
const performanceBondMinDurationInMonths = 1
const performanceBondMaxDurationInMonths = 24
const defaultPerformanceDurationMonths = performanceBondMinDurationInMonths

export function isPositive(amount: number): boolean {
  return amount >= 1
}

interface BondDetailParameter {
  defaultDuration?: number
  i18nKeySuffix: string
  updateBondDuration?: (oldDuration: IDuration, newDuration: number) => IDuration
  validateDuration: (duration?: number) => boolean
  i18nDurationErrorKey: string | { key: string; values: Record<string, ReactNode> }
  i18nDatesErrorKey: string
}

const bondDetailsParameters: { [key: string]: BondDetailParameter } = {
  BID: {
    defaultDuration: defaultBidDurationDays,
    i18nKeySuffix: 'bid',
    updateBondDuration: (oldDuration, newDuration) => {
      return { ...oldDuration, days: newDuration }
    },
    validateDuration: daysNumber =>
      !!daysNumber && bidBondMinDurationInDays <= daysNumber && daysNumber <= bidBondMaxDurationInDays,
    i18nDurationErrorKey: {
      key: 'invalid-days-duration',
      values: { maxDuration: bidBondMaxDurationInDays },
    },
    i18nDatesErrorKey: '',
  },
  PERFORMANCE: {
    defaultDuration: defaultPerformanceDurationMonths,
    i18nKeySuffix: 'performance',
    updateBondDuration: (oldDuration, newDuration) => {
      return { ...oldDuration, months: newDuration }
    },
    validateDuration: monthsNumber =>
      !!monthsNumber &&
      performanceBondMinDurationInMonths <= monthsNumber &&
      monthsNumber <= performanceBondMaxDurationInMonths,
    i18nDurationErrorKey: 'invalid-year',
    i18nDatesErrorKey: '',
  },
  LIS: {
    i18nKeySuffix: 'lis',
    validateDuration: _ => true,
    i18nDurationErrorKey: 'not-used',
    i18nDatesErrorKey: 'invalid-duration-year-exact',
  },
  UNA_TANTUM: {
    i18nKeySuffix: 'una-tantum',
    validateDuration: _ => true,
    i18nDurationErrorKey: 'not-used',
    i18nDatesErrorKey: 'invalid-duration-year-exact',
  },
  TAX_STAMPS: {
    i18nKeySuffix: 'tax-stamps',
    validateDuration: _ => true,
    i18nDurationErrorKey: 'not-used',
    i18nDatesErrorKey: 'invalid-tax-stamps-end-date',
  },
  LOTTERY: {
    i18nKeySuffix: 'lottery',
    validateDuration: _ => true,
    i18nDurationErrorKey: 'not-used',
    i18nDatesErrorKey: 'invalid-duration-max-two-years',
  },
  LOTTO: {
    i18nKeySuffix: 'lotto',
    validateDuration: _ => true,
    i18nDurationErrorKey: 'not-used',
    i18nDatesErrorKey: 'invalid-duration-max-two-years',
  },
  NINE_YEAR: {
    i18nKeySuffix: 'nine-year',
    validateDuration: _ => true,
    i18nDurationErrorKey: 'not-used',
    i18nDatesErrorKey: 'invalid-duration-max-nine-years',
  },
}

export default bondDetailsParameters
