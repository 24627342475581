/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useCallback, useContext, useMemo } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { FormattedMessage, useIntl } from 'react-intl'
import _ from 'lodash'
import { Button, StepComponent } from '@gsp/gusto-front-common'
import { MdPrint } from 'react-icons/md'

import BondContext from '../../../../models/BondContext'
import { isBondFinalized } from '../../../../utils/bondUtils'

import summaryParameters from './summaryParameters'
import SummaryGroup from './SummaryGroup/SummaryGroup'

import './Summary.scss'

const Summary = ({ goToNextStep, goToPreviousStep, currentIndex }: StepComponent) => {
  const intl = useIntl()
  const { bond, setBond } = useContext(BondContext)
  const blocks = summaryParameters[bond.bondType]
  const rows = _.chunk(blocks, 2)

  const goToLastStep = useCallback(() => {
    goToPreviousStep(currentIndex - 1)
  }, [currentIndex, goToPreviousStep])

  const isValid = useMemo(
    () => !bond.customGrossPremiumAmount || bond.customGrossPremiumAmount >= bond.grossPremiumAmount!,
    [bond.customGrossPremiumAmount, bond.grossPremiumAmount]
  )

  return (
    <div className="summary-data" data-testid="summary-data-container">
      <div className="print-only">
        <h3 className="title text-blue">{bond.company?.companyName}</h3>
        <p className="label">{bond.project.bidBondNumber}</p>
      </div>
      <div className="print-button-container">
        <button onClick={window.print} className="button-secondary mr-3 screen-only">
          <MdPrint size="2em" />
          &nbsp;
          <FormattedMessage id="print" />
        </button>
      </div>
      <Container>
        {rows.map(row => (
          <Row key={row[0].title}>
            {row.map(block => (
              <Col className="p-3" key={block.title}>
                <SummaryGroup
                  title={`summary-${block.title}`}
                  items={block.items.map(({ getValue, ...item }) => ({
                    ...item,
                    value: getValue(bond, intl),
                  }))}
                  dataTestid={`summary-group-${block.title}`}
                />
              </Col>
            ))}
          </Row>
        ))}
      </Container>
      {!isBondFinalized(bond) && (
        <Container>
          <div className="summary-controls">
            <div className="summary-controls-1">
              <Button type="secondary" dataTestid="previous-step" onClick={goToLastStep} i18nKey={'previous-step'} />
            </div>
            <div className="summary-controls-2">
              <div className="subscribe-button-group">
                <Button
                  dataTestid="save"
                  i18nKey="summary-save"
                  onClick={() => {
                    setBond({ ...bond, bondStateCode: 'DRF' })
                    if (goToNextStep) {
                      goToNextStep()
                    }
                  }}
                  disabled={!isValid}
                  className="mr-2"
                />
                <Button
                  dataTestid="subscribe"
                  i18nKey="summary-finish"
                  onClick={() => {
                    setBond({ ...bond, bondStateCode: 'FIN' })
                    if (goToNextStep) {
                      goToNextStep()
                    }
                  }}
                  disabled={!isValid}
                />
              </div>
            </div>
          </div>
        </Container>
      )}
    </div>
  )
}

export default Summary
