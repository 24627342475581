import React, { ReactNode, useCallback, useContext, useEffect, useReducer, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Col, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import {
  Banner,
  Dropdown,
  getLocaleFormatter,
  Input,
  LanguageContext,
  Loader,
  LocaleFormatter,
} from '@gsp/gusto-front-common'

import IQuotationData from '../../../models/IQuotationData'
import BondContext from '../../../models/BondContext'

import { fetchQuotationList, loadQuotationBondData } from '../../../services/quotationsList'

import QuotationDataCard from './QuotationDataCard/QuotationDataCard'

import './QuotationsList.scss'

const statusMap: Record<string, string> = {
  ISS: 'Issued',
  REJ: 'Rejected',
  EXP: 'Expired',
  DRF: 'Draft',
  ACP: 'Accepted',
  PND: 'Pending',
}

const getStatus = (quotationData: IQuotationData) => statusMap[quotationData.quotationState] || 'Unknown'

type OrderBy = 'order-by-date-asc' | 'order-by-date-desc' | 'order-by-amount-asc' | 'order-by-amount-desc'

enum ActionTypes {
  SET_INITIAL_LIST,
  RESET,
  SET_COMPANY_NAME_FILTER,
  SET_BOND_TYPE_FILTER,
  SET_STATUS_FILTER,
  FILTER,
  SET_ORDER_BY,
  ORDER,
}
type Action =
  | { type: ActionTypes.RESET }
  | { type: ActionTypes.FILTER }
  | { type: ActionTypes.SET_INITIAL_LIST; data: IQuotationData[] }
  | { type: ActionTypes.SET_COMPANY_NAME_FILTER; companyName: string }
  | { type: ActionTypes.SET_BOND_TYPE_FILTER; bondType: string }
  | { type: ActionTypes.SET_STATUS_FILTER; status: string }
  | { type: ActionTypes.SET_ORDER_BY; orderBy: OrderBy }
  | { type: ActionTypes.ORDER }

interface ListState {
  data: IQuotationData[]
  initialData: IQuotationData[]
  filterCompanyName: string
  filterBondType: string
  filterStatus: string
  orderBy: OrderBy
}

const filterQuotationByCompanyName = (quotation: IQuotationData, filterCompanyName: string) => {
  return quotation.companyName.toLowerCase().includes(filterCompanyName.toLowerCase())
}

const filterQuotationByBondType = (quotation: IQuotationData, filterBondType: string) => {
  if (filterBondType) {
    return filterBondType === quotation.bondType
  }
  return true
}

const filterQuotationByStatus = (quotation: IQuotationData, filterStatus: string) => {
  if (filterStatus === 'unkown') {
    return quotation.status === undefined
  }
  if (filterStatus) {
    return filterStatus === quotation.status
  }
  return true
}

const filterQuotation = (
  quotation: IQuotationData,
  filterCompanyName: string,
  filterBondType: string,
  filterStatus: string
) => {
  return (
    filterQuotationByCompanyName(quotation, filterCompanyName) &&
    filterQuotationByBondType(quotation, filterBondType) &&
    filterQuotationByStatus(quotation, filterStatus)
  )
}

const orderQuotations = (data: IQuotationData[], orderBy: OrderBy) => {
  return data.sort((first, second) => {
    const isByDate = orderBy.includes('order-by-date')
    const firstValue = isByDate ? first.receivedTimestamp.valueOf() : first.bondAmount.value
    const secondValue = isByDate ? second.receivedTimestamp.valueOf() : second.bondAmount.value
    const factor = orderBy.includes('asc') ? 1 : -1
    if (firstValue > secondValue) {
      return factor
    }
    if (firstValue < secondValue) {
      return -factor
    }
    return 0
  })
}

const reducer = (state: ListState, action: Action) => {
  switch (action.type) {
    case ActionTypes.SET_INITIAL_LIST:
      return {
        ...state,
        data: action.data,
        initialData: action.data,
      }
    case ActionTypes.RESET:
      return {
        ...state,
        data: state.initialData,
      }
    case ActionTypes.SET_COMPANY_NAME_FILTER:
      return {
        ...state,
        filterCompanyName: action.companyName,
      }
    case ActionTypes.SET_BOND_TYPE_FILTER:
      return {
        ...state,
        filterBondType: action.bondType,
      }
    case ActionTypes.SET_STATUS_FILTER:
      return {
        ...state,
        filterStatus: action.status,
      }
    case ActionTypes.FILTER:
      return {
        ...state,
        data: state.initialData.filter(quotation => {
          return filterQuotation(quotation, state.filterCompanyName, state.filterBondType, state.filterStatus)
        }),
      }
    case ActionTypes.SET_ORDER_BY:
      return {
        ...state,
        orderBy: action.orderBy,
      }
    case ActionTypes.ORDER:
      return {
        ...state,
        data: orderQuotations(state.data, state.orderBy),
      }
    default:
      return state
  }
}

const orderByDropdownItems: OrderBy[] = [
  'order-by-date-desc',
  'order-by-date-asc',
  'order-by-amount-desc',
  'order-by-amount-asc',
]

const dropdownMapper = (label: string) => {
  return (item: string) => {
    return {
      code: item,
      labelKey: `${label}-${item.toLowerCase()}`,
    }
  }
}

const QuotationsList = () => {
  const [state, dispatch] = useReducer(reducer, {
    data: [],
    initialData: [],
    filterCompanyName: '',
    filterBondType: '',
    filterStatus: '',
    orderBy: orderByDropdownItems[0],
  })
  const intl = useIntl()
  const navigate = useNavigate()

  const { data, initialData, filterCompanyName, filterBondType, filterStatus } = state

  const openBondRequest = () => navigate('/bond-request')
  const openBondRequestSummary = () => navigate('/bond-request/summary')

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<{
    title: string
    message: string
  }>()
  const [bondTypes, setBondTypes] = useState<string[]>([])
  const [status, setStatus] = useState<string[]>([])

  const { setBond } = useContext(BondContext)
  const { localeCode } = useContext(LanguageContext)
  const localeFormatter = getLocaleFormatter(localeCode)

  const createBond = useCallback(
    (q: IQuotationData) => {
      loadQuotationBondData(setBond, q, openBondRequest)
    },
    [setBond, openBondRequest]
  )
  const updateBond = useCallback(
    (q: IQuotationData) => {
      loadQuotationBondData(setBond, q, openBondRequest)
    },
    [setBond, openBondRequest]
  )
  const subscribeBond = useCallback(
    (q: IQuotationData) => {
      loadQuotationBondData(setBond, q, openBondRequestSummary)
    },
    [setBond, openBondRequest]
  )
  const viewBond = useCallback(
    (q: IQuotationData) => {
      loadQuotationBondData(setBond, q, openBondRequestSummary)
    },
    [setBond, openBondRequest]
  )

  useEffect(() => {
    fetchQuotationList(1, 50)
      .then(resp => {
        const list: IQuotationData[] = resp.quotationData.map(element => ({
          ...element,
          status: getStatus(element),
        }))
        dispatch({ type: ActionTypes.SET_INITIAL_LIST, data: list })
        dispatch({ type: ActionTypes.ORDER })
        setLoading(false)
      })
      .catch(e => {
        setLoading(false)
        setError({
          title: 'error-technical',
          message: 'error-fetch-quotations',
        })
      })
  }, [dispatch])

  useEffect(() => {
    const bondTypeSet = new Set(
      (filterStatus === '' && filterCompanyName === '' ? initialData : data).map(quotation => quotation.bondType)
    )
    const statusSet = new Set(
      (filterBondType === '' && filterCompanyName === '' ? initialData : data).map(
        quotation => quotation.status || 'unkown'
      )
    )
    setBondTypes(Array.from(bondTypeSet))
    setStatus(Array.from(statusSet))
  }, [data, initialData, filterBondType, filterStatus, filterCompanyName])

  const handleCompanyNameFilterChange = useCallback(
    (companyName: string) => {
      dispatch({
        type: ActionTypes.SET_COMPANY_NAME_FILTER,
        companyName,
      })
      dispatch({ type: ActionTypes.FILTER })
    },
    [dispatch]
  )

  const handleBondTypeFilterChange = useCallback(
    (bondType: string) => {
      dispatch({ type: ActionTypes.SET_BOND_TYPE_FILTER, bondType })
      dispatch({ type: ActionTypes.FILTER })
    },
    [dispatch]
  )

  const handleStatusFilterChange = useCallback(
    (value: string) => {
      dispatch({ type: ActionTypes.SET_STATUS_FILTER, status: value })
      dispatch({ type: ActionTypes.FILTER })
    },
    [dispatch]
  )

  const handleOrderChange = useCallback(
    (order: string) => {
      const orderBy = order as OrderBy
      dispatch({ type: ActionTypes.SET_ORDER_BY, orderBy })
      dispatch({ type: ActionTypes.ORDER })
    },
    [dispatch]
  )

  return (
    <div className="quotation-list-container">
      {loading || error ? (
        noResponseReceived(error)
      ) : (
        <>
          <Row id="quotation-list-header" className="pb-2">
            <Col lg={2} className="quotation-list-count">
              {quotationListCount(data.length)}
            </Col>
          </Row>
          <div className="quotation-list-controls">
            <span>
              <Input
                labelKey="quotation-list-filter-by"
                value={filterCompanyName}
                changeValue={handleCompanyNameFilterChange}
                placeholder={intl.formatMessage({
                  id: 'quotation-list-company-name',
                })}
              />
              <span className="quotation-list-filter-by-bond-type">
                <Dropdown
                  labelKey="quotation-list-filter-by-bond-type"
                  items={[
                    { code: '', labelKey: 'bond-type-all' },
                    ...bondTypes.sort((a, b) => a.localeCompare(b)).map(dropdownMapper('bond-type')),
                  ]}
                  onChange={handleBondTypeFilterChange}
                />
              </span>
            </span>
            <span>
              <Dropdown
                labelKey="quotation-list-filter-by-status"
                items={[
                  { code: '', labelKey: 'bond-status-all' },
                  ...status.sort((a, b) => a.localeCompare(b)).map(dropdownMapper('bond-status')),
                ]}
                onChange={handleStatusFilterChange}
              />
              <Dropdown
                labelKey="order-by-label"
                items={orderByDropdownItems.map(item => {
                  return { code: item, labelKey: item }
                })}
                onChange={handleOrderChange}
              />
            </span>
          </div>
          <hr className="header__separator" />
          <div id="quotation-list" role="list">
            {quotationListDisplay(data, localeFormatter, createBond, updateBond, subscribeBond, viewBond)}
          </div>
        </>
      )}
    </div>
  )
}

const noResponseReceived = (error: { title: string; message: string } | undefined) => {
  if (error) {
    return <Banner bannerType="error" bannerTitleKey={error.title} bannerMessageKey={error.message} />
  }
  return <Loader isVisible />
}

const quotationListCount = (quotationListLength: number) => {
  if (quotationListLength > 0) {
    const listCount = (...chunks: Array<ReactNode>) => <strong>{chunks}</strong>

    return (
      <FormattedMessage
        id="quotation-list-count"
        values={{
          count: quotationListLength,
          listCount,
        }}
      />
    )
  }
  return <FormattedMessage id="quotation-list-count-empty" />
}

const quotationListDisplay = (
  data: IQuotationData[],
  localeFormatter: LocaleFormatter,
  createBond: (q: IQuotationData) => void,
  updateBond: (q: IQuotationData) => void,
  subscribeBond: (q: IQuotationData) => void,
  viewBond: (q: IQuotationData) => void
) => {
  if (data && data.length > 0) {
    return data.map((quotation: IQuotationData) => {
      return (
        <QuotationDataCard
          quotation={quotation}
          key={quotation.id}
          localeFormatter={localeFormatter}
          createBond={createBond}
          updateBond={updateBond}
          subscribeBond={subscribeBond}
          viewBond={viewBond}
        />
      )
    })
  }
  return (
    <div className="quotation-list-message">
      <FormattedMessage id="quotation-list-empty" />
    </div>
  )
}

export default QuotationsList
