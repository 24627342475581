import React, { useContext, useState, useCallback, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { Button, Dropdown, Input, isValidEmail, Loader, StepComponent } from '@gsp/gusto-front-common'

import Beneficiary from '../../../../models/Beneficiary'
import BondContext from '../../../../models/BondContext'
import getTenderDataFromCig from '../../../../services/getTenderDataFromCig'

import './BeneficiaryInformation.scss'
import defaultBeneficiaries from './defaultBeneficiaries'

const emptyBeneficiary = {
  addressName2: '',
  cityName: '',
  countryCode: 'IT',
  stateCode: '',
  addressName1: '',
  emailAddress: '',
  postalCode: '',
  postalCodeTypeCode: 'MAIN',
  streetNumber: '',
  street: '',
}

// all fields
type beneficiaryFieldsT =
  | 'addressName2'
  | 'cityName'
  | 'countryCode'
  | 'stateCode'
  | 'addressName1'
  | 'postalCode'
  | 'emailAddress'
  | 'street'
  | 'streetNumber'

// mandatory fields
const tendersMandatoryFields: beneficiaryFieldsT[] = [
  'cityName',
  'countryCode',
  'street',
  'stateCode',
  'addressName1',
  'emailAddress',
]

const tobacconistMandatoryFields: beneficiaryFieldsT[] = [
  'cityName',
  'countryCode',
  'street',
  'stateCode',
  'addressName1',
]

interface BeneficiaryFieldProps {
  disabled: boolean
  field: beneficiaryFieldsT
  onChange: (value: string, field: beneficiaryFieldsT, isValid: boolean) => void
  value: string
  isEmail?: boolean
  maxLength?: number
  isRequired?: boolean
}

// custom inputs
const BeneficiaryFieldInput = ({ field, disabled, onChange, value, maxLength, isRequired }: BeneficiaryFieldProps) => {
  const handleInputChange = (inputValue: string) => {
    onChange(inputValue, field, inputValue !== '')
  }
  return (
    <Input
      dataTestid={field}
      value={value}
      changeValue={handleInputChange}
      labelKey={`beneficiary-field-${field}`}
      isRequired={isRequired}
      disabled={disabled}
      maxLength={maxLength}
      forceUppercase
    />
  )
}

const BeneficiaryInformation = ({ setStepCompleted }: StepComponent) => {
  const { bond, setBond } = useContext(BondContext)

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [cig, setCig] = useState(bond.cig ? bond.cig : '')
  const hasDefaultBeneficiary = Object.prototype.hasOwnProperty.call(defaultBeneficiaries, bond.bondType)
  // initial state (empty beneficiary)
  const beneficiary = hasDefaultBeneficiary
    ? defaultBeneficiaries[bond.bondType]!
    : bond.beneficiary || emptyBeneficiary

  const fieldsEnabled = !loading && !hasDefaultBeneficiary
  const mandatoryFields = bond.intermediaryType === 'TENDERS' ? tendersMandatoryFields : tobacconistMandatoryFields

  const handleBeneficiaryFieldChange = useCallback(
    (value: string, field: beneficiaryFieldsT, isValid: boolean) => {
      const newBeneficiary = {
        ...beneficiary,
        [field]: value,
      }
      setBond({ ...bond, beneficiary: newBeneficiary })
    },
    [beneficiary, bond, setBond]
  )

  const loadBeneficiary = useCallback(() => {
    if (loading) {
      return
    }

    // get all data from beneficaryData (from TELEMAT API) and put them in beneficary state
    // respect the pattern in order to facilitate the mandatoryFields verification (see below)
    setLoading(true)
    getTenderDataFromCig(cig)
      .then(result => {
        const converted: Beneficiary = {
          addressName2: result.beneficiaryData.addressComplement || '',
          cityName: result.beneficiaryData.city || '',
          countryCode: result.beneficiaryData.countryCode || '',
          stateCode: result.beneficiaryData.district || '',
          addressName1: result.beneficiaryData.beneficiaryName || '',
          emailAddress: beneficiary.emailAddress || '',
          postalCode: result.beneficiaryData.postalCode || '',
          streetNumber: result.beneficiaryData.streetNumber || '',
          street: result.beneficiaryData.streetName || '',
          postalCodeTypeCode: result.beneficiaryData.postalCode || '',
        }

        setError('')
        setLoading(false)
        const description =
          result.projectDescription.length < 500 ? result.projectDescription : result.projectDescription.slice(0, 500)
        setBond({
          ...bond,
          beneficiary: converted,
          project: {
            ...bond.project,
            description,
            amount: result.amount,
          },
        })
      })
      .catch((e: string) => {
        const resetBeneficiary = {
          ...emptyBeneficiary,
        }
        setError(e)
        setLoading(false)
        setBond({
          ...bond,
          beneficiary: resetBeneficiary,
          project: {
            ...bond.project,
            description: '',
            amount: null,
          },
        })
      })
  }, [beneficiary.emailAddress, bond, cig, loading, setBond])

  const handleCigChange = useCallback(
    (value: string) => {
      setCig(value)
      setBond({ ...bond, cig: value })
    },
    [setCig, setBond, bond]
  )

  const [errorEmail, setErrorEmail] = useState(false)
  const handleEmailChange = useCallback(
    (value: string) => {
      const isValid = !value || isValidEmail(value)
      setErrorEmail(!isValid)
      handleBeneficiaryFieldChange(value, 'emailAddress', isValid)
    },
    [setErrorEmail, handleBeneficiaryFieldChange]
  )

  useEffect(() => {
    setStepCompleted(
      (cig !== '' || bond.bondType !== 'BID') &&
        mandatoryFields.every(field => beneficiary[field] !== '') &&
        !errorEmail
    )
  }, [setStepCompleted, cig, bond.bondType, beneficiary, errorEmail])

  useEffect(() => {
    if (!bond.beneficiary) {
      setBond({
        ...bond,
        beneficiary,
      })
    }
  }, [])

  return (
    <div className="bond-request-form beneficiary-information" data-testid="beneficiary-information-wrapper">
      <div className="beneficiary-details-form">
        {bond.intermediaryType === 'TENDERS' && (
          <>
            <Row>
              <Col sm={6}>
                <Input
                  dataTestid="beneficiary-company-cig"
                  labelKey="beneficiary-company-cig"
                  value={cig}
                  changeValue={handleCigChange}
                  isRequired={bond.bondType === 'BID'}
                  forceUppercase
                />
              </Col>
              <Col sm={6} className="centered-column">
                <Button
                  dataTestid="load-beneficiary-by-cig"
                  i18nKey="beneficiary-company-search"
                  onClick={loadBeneficiary}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="beneficiary-search-status">
                  <Loader isVisible={loading} />
                  {error && !loading && (
                    <p>
                      <FormattedMessage id="beneficiary-cig-error" />
                    </p>
                  )}
                </div>
              </Col>
            </Row>
          </>
        )}
        <Row>
          <Col sm={6}>
            <BeneficiaryFieldInput
              disabled={!fieldsEnabled}
              field="addressName1"
              onChange={handleBeneficiaryFieldChange}
              value={beneficiary.addressName1}
              maxLength={55}
              isRequired={mandatoryFields.includes('addressName1')}
            />
          </Col>
          <Col sm={6}>
            <BeneficiaryFieldInput
              disabled={!fieldsEnabled}
              field="addressName2"
              onChange={handleBeneficiaryFieldChange}
              value={beneficiary.addressName2}
              maxLength={55}
              isRequired={mandatoryFields.includes('addressName2')}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Row className="beneficiary-street">
              <Col>
                <BeneficiaryFieldInput
                  disabled={!fieldsEnabled}
                  field="street"
                  onChange={handleBeneficiaryFieldChange}
                  value={beneficiary.street}
                  isRequired={mandatoryFields.includes('street')}
                />
              </Col>
            </Row>
          </Col>
          <Col sm={6}>
            <Row>
              <Col xs={4}>
                <BeneficiaryFieldInput
                  disabled={!fieldsEnabled}
                  field="streetNumber"
                  onChange={handleBeneficiaryFieldChange}
                  value={beneficiary.streetNumber}
                  isRequired={mandatoryFields.includes('streetNumber')}
                />
              </Col>
              <Col xs={8}>
                <BeneficiaryFieldInput
                  disabled={!fieldsEnabled}
                  field="postalCode"
                  onChange={handleBeneficiaryFieldChange}
                  value={beneficiary.postalCode}
                  isRequired={mandatoryFields.includes('postalCode')}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <BeneficiaryFieldInput
              disabled={!fieldsEnabled}
              field="stateCode"
              onChange={handleBeneficiaryFieldChange}
              value={beneficiary.stateCode}
              isRequired={mandatoryFields.includes('stateCode')}
            />
          </Col>
          <Col sm={6}>
            <BeneficiaryFieldInput
              disabled={!fieldsEnabled}
              field="cityName"
              onChange={handleBeneficiaryFieldChange}
              value={beneficiary.cityName}
              isRequired={mandatoryFields.includes('cityName')}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Dropdown
              disabled={!fieldsEnabled}
              dataTestid="countryCode"
              labelKey="beneficiary-field-countryCode"
              items={[{ code: 'IT', labelKey: 'country-it' }]}
              onChange={(value: string, valid: boolean) => handleBeneficiaryFieldChange(value, 'countryCode', valid)}
              mandatory
            />
          </Col>
          <Col sm={6}>
            <Input
              dataTestid="emailAddress"
              value={beneficiary.emailAddress}
              changeValue={handleEmailChange}
              labelKey="beneficiary-field-emailAddress"
              isRequired={mandatoryFields.includes('emailAddress')}
              defaultError={errorEmail ? 'invalid-value' : ''}
              disabled={!fieldsEnabled}
              forceUppercase
            />
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default BeneficiaryInformation
