import _ from 'lodash'

import { ClientInformationState } from './ClientInformation'

type Action = {
  type: 'UPDATE_CLIENT_FIELD'
  payload: {
    fieldName: string
    value: string | Date | null
  }
}

function reducer(state: ClientInformationState, action: Action) {
  const newState = {
    ...state,
    client: {
      ...state.client,
    },
    project: {
      ...state.project,
    },
  }

  switch (action.type) {
    case 'UPDATE_CLIENT_FIELD':
      _.set(newState.client, action.payload.fieldName, action.payload.value)
  }

  return newState
}

export default reducer
