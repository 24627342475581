import React, { useState, useCallback, useContext } from 'react'
import { IntlProvider } from 'react-intl'
import { getUserLang, getMessages, getLocaleCode } from '../../i18n/i18n'
import { CookieContext, getCookie, Language, LanguageContext, setCookie } from '@gsp/gusto-front-common'

import IntermediariesLanguage from '../../i18n/IntermediariesLanguage'

const initialLanguage = getUserLang(getCookie('userLang') || navigator.language.slice(0, 2))

export const LanguageContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { preferences } = useContext(CookieContext)
  const [userLang, setUserLang] = useState(initialLanguage)
  const [localeCode, setLocaleCode] = useState(getLocaleCode(initialLanguage))
  const [messages, setMessages] = useState(getMessages(initialLanguage))
  const refreshLanguage = useCallback(
    (language: Language) => {
      if (preferences.permanent) {
        setCookie('userLang', language)
      }
      setUserLang(language as IntermediariesLanguage)
      setLocaleCode(getLocaleCode(language as IntermediariesLanguage))
      setMessages(getMessages(language as IntermediariesLanguage))
    },
    [preferences.permanent]
  )

  return (
    <LanguageContext.Provider
      value={{
        userLang,
        localeCode,
        messages,
        refreshLang: refreshLanguage,
      }}
    >
      <IntlProvider locale={userLang} messages={messages}>
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  )
}
