export enum QuotationReasons {
  REJECTED = 'REJ',
  PENDING = 'PND',
  SANCTION = 'SCT',
}

export default interface IQuotationRequestResponse {
  bondNumber: string
  bondQuotationRequestId: string
  contractId: string
  extensionId: string
  premiumAmount: number
  premiumCurrency: string
  taxAmount: number
  taxAmountCurrency: string
  grossPremiumAmount: number
  grossPremiumCurrency: string
  responseCode: string
  reasonForResponseCode: QuotationReasons
  contractNumber?: string
}
