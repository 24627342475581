import { durationToString, getLocaleFormatter } from '@gsp/gusto-front-common'
import { format } from 'date-fns'
import { ReactNode } from 'react'
import { IntlShape } from 'react-intl'
import Address from '../../../../models/Address'
import Bond from '../../../../models/Bond'
import BondType from '../../../../models/BondType'
import { PremiumValue } from '../PremiumValue/PremiumValue'

const localeFormatter = getLocaleFormatter('fr-FR')

const buildAddress = (address?: Address) => {
  let clientAddress = ''
  if (address?.streetName) {
    clientAddress += address.streetName
  }
  if (address?.streetNumber) {
    clientAddress += `, ${address.streetNumber}`
  }
  return clientAddress
}

const isBondFinalizedStateCode = (bond: Bond) => ['ACP', 'FIN'].includes(bond.bondStateCode || 'DRF')

const getBondPremiumAmount = (bond: Bond) =>
  bond.customGrossPremium ? bond.customGrossPremiumAmount : bond.grossPremiumAmount

const getBondPremiumValueBlock = (bond: Bond) => {
  if (!bond.grossPremiumAmount) {
    return ''
  }

  return isBondFinalizedStateCode(bond)
    ? localeFormatter.formatCurrency(getBondPremiumAmount(bond) || 0, 'EUR')
    : PremiumValue()
}

const searchCompanyIdentifier = (bond, idTypeCode, countryCode = 'IT') => {
  return (
    bond.company?.localIdentifiers?.find(
      identifier => identifier.countryCode === countryCode && identifier.idTypeCode === idTypeCode
    )?.idValue ?? ''
  )
}

interface SummaryItemParam {
  label: string
  getValue: (bond: Bond, intl: IntlShape) => ReactNode
  testId?: string
  hiddenLabel?: boolean
  tooltip?: string
}

interface SummaryBlockParam {
  title: string
  items: SummaryItemParam[]
}

const tobacconistClientBlock: SummaryBlockParam = {
  title: 'client',
  items: [
    {
      label: 'summary-client-name',
      getValue: bond => bond.company?.companyName ?? '',
    },
    {
      label: 'summary-client-identifier',
      getValue: bond => bond.company?.identifier ?? '',
    },
    {
      label: 'summary-client-address',
      getValue: bond => buildAddress(bond.company?.address),
    },
    {
      label: 'summary-client-postal-code',
      getValue: bond => bond.company?.address.postCode ?? '',
    },
    {
      label: 'summary-client-city',
      getValue: bond => bond.company?.address.town ?? '',
    },
  ],
}

const tendersClientBlock: SummaryBlockParam = {
  ...tobacconistClientBlock,
  items: [
    ...tobacconistClientBlock.items,
    {
      label: 'summary-client-pec',
      getValue: bond => bond.additional?.pec ?? '',
    },
  ],
}

const tendersProjectBlock: SummaryBlockParam = {
  title: 'project-description',
  items: [
    {
      label: 'summary-project-amount',
      getValue: bond => (bond.project?.amount ? localeFormatter.formatCurrency(bond.project.amount, 'EUR') : ''),
    },
    {
      label: 'summary-project-cig-number',
      getValue: bond => bond.cig ?? '',
    },
    {
      label: 'summary-project-description',
      getValue: bond => bond.project?.description ?? '',
    },
    {
      label: 'summary-project-bid-bond-number',
      getValue: bond => bond.project?.bidBondNumber ?? '',
    },
    {
      label: 'summary-project-renewal',
      getValue: (bond, intl) =>
        intl.formatMessage({
          id: `summary-${
            (bond.project && bond.project.renewal !== 'NONE' && bond.project.renewal) || 'project-renewal-none'
          }`,
        }),
    },
  ],
}

const tobacconistPrincipalFirstItems: SummaryItemParam[] = [
  {
    label: 'summary-principal-name',
    getValue: bond => bond.client?.principalName ?? '',
  },
  {
    label: 'summary-principal-fiscal-code',
    getValue: bond => searchCompanyIdentifier(bond, 'COFIS', 'IT'),
  },
  {
    label: 'summary-principal-vat-number',
    getValue: bond => searchCompanyIdentifier(bond, 'TVAIT', 'IT'),
  },
]

const tobacconistPrincipalPlaceOfBirthItem: SummaryItemParam = {
  label: 'summary-principal-place-of-birth',
  getValue: bond => bond.client?.principalPlaceOfBirth ?? '',
}

const tobacconistPrincipalAddressItems: SummaryItemParam[] = [
  {
    label: 'summary-principal-address',
    getValue: () => '',
  },
  {
    label: 'summary-principal-street',
    getValue: bond => bond.client?.principalAddress?.streetName ?? '',
  },
  {
    label: 'summary-principal-street-number',
    getValue: bond => bond.client?.principalAddress?.streetNumber ?? '',
  },
  {
    label: 'summary-principal-postal-code',
    getValue: bond => bond.client?.principalAddress?.postCode ?? '',
  },
  {
    label: 'summary-principal-district',
    getValue: bond => bond.client?.principalAddress?.stateCode ?? '',
  },
  {
    label: 'summary-principal-town',
    getValue: bond => bond.client?.principalAddress?.town ?? '',
  },
]

const tobacconistSimplePrincipalBlock: SummaryBlockParam = {
  title: 'principal',
  items: [...tobacconistPrincipalFirstItems, ...tobacconistPrincipalAddressItems],
}

const tobacconistPrincipalBlockWithBirthPlace: SummaryBlockParam = {
  ...tobacconistSimplePrincipalBlock,
  items: [...tobacconistPrincipalFirstItems, tobacconistPrincipalPlaceOfBirthItem, ...tobacconistPrincipalAddressItems],
}

const tobacconistUnaTantumPrincipalBlock: SummaryBlockParam = {
  ...tobacconistSimplePrincipalBlock,
  items: [
    ...tobacconistPrincipalFirstItems,
    {
      label: 'summary-principal-date-of-birth',
      getValue: bond =>
        bond.client?.principalDateOfBirth ? format(bond.client.principalDateOfBirth, 'dd/MM/yyyy') : '',
    },
    tobacconistPrincipalPlaceOfBirthItem,
    ...tobacconistPrincipalAddressItems,
    {
      label: 'summary-principal-private-address',
      getValue: () => '',
    },
    {
      label: 'summary-principal-street',
      getValue: bond => bond.client?.privateAddress?.streetName ?? '',
      testId: 'summary-principal-private-street',
    },
    {
      label: 'summary-principal-street-number',
      getValue: bond => bond.client?.privateAddress?.streetNumber ?? '',
      testId: 'summary-principal-private-street-number',
    },
    {
      label: 'summary-principal-postal-code',
      getValue: bond => bond.client?.privateAddress?.postCode ?? '',
      testId: 'summary-principal-private-postal-code',
    },
    {
      label: 'summary-principal-town',
      getValue: bond => bond.client?.privateAddress?.town ?? '',
      testId: 'summary-principal-private-town',
    },
  ],
}

const beneficiaryBlock: SummaryBlockParam = {
  title: 'beneficiary',
  items: [
    {
      label: 'summary-beneficiary-name',
      getValue: bond => bond.beneficiary?.addressName1 ?? '',
    },
    {
      label: 'summary-beneficiary-address',
      getValue: bond => bond.beneficiary?.addressName2 ?? '',
      hiddenLabel: true,
    },
    {
      label: 'summary-beneficiary-postal-code',
      getValue: bond => bond.beneficiary?.postalCode ?? '',
    },
    {
      label: 'summary-beneficiary-city',
      getValue: bond => bond.beneficiary?.cityName ?? '',
    },
    {
      label: 'summary-beneficiary-district',
      getValue: bond => bond.beneficiary?.stateCode ?? '',
    },
    {
      label: 'summary-beneficiary-streetNumber',
      getValue: bond => bond.beneficiary?.streetNumber ?? '',
    },
    {
      label: 'summary-beneficiary-street',
      getValue: bond => bond.beneficiary?.street ?? '',
    },
    {
      label: 'summary-beneficiary-pec',
      getValue: bond => bond.beneficiary?.emailAddress ?? '',
    },
  ],
}

const priceFirstItems: SummaryItemParam[] = [
  {
    label: 'summary-price-bond-type',
    getValue: (bond, intl) =>
      intl.formatMessage({
        id: `summary-${bond.bondType}`,
      }),
  },
  {
    label: 'summary-price-bond-amount',
    getValue: bond => localeFormatter.formatCurrency(bond.amount, 'EUR'),
  },
]

const priceAdditionalItems: SummaryItemParam[] = [
  {
    label: 'summary-price-signature-type',
    getValue: bond => bond.additional?.authenticationType,
  },
  {
    label: 'summary-price-signature-format',
    getValue: bond => bond.additional?.digitalSignatureFormat,
  },
]

const tendersPriceBlock: SummaryBlockParam = {
  title: 'price',
  items: [
    ...priceFirstItems,
    {
      label: 'summary-price-bond-duration',
      getValue: (bond, intl) => durationToString(bond.duration, intl),
    },
    {
      label: 'summary-price-date-submission',
      getValue: bond => bond.startDate && format(bond.startDate, 'dd/MM/yyyy'),
    },
    ...priceAdditionalItems,
    {
      label: 'summary-price',
      getValue: bond => getBondPremiumValueBlock(bond),
    },
  ],
}

const tobacconistPriceBlock: SummaryBlockParam = {
  ...tendersPriceBlock,
  items: [
    ...priceFirstItems,
    {
      label: 'summary-price-start-date',
      getValue: bond => bond.startDate && format(bond.startDate, 'dd/MM/yyyy'),
    },
    {
      label: 'summary-price-end-date',
      getValue: bond => bond.endDate && format(bond.endDate, 'dd/MM/yyyy'),
    },
    {
      label: 'summary-price-description',
      getValue: bond => bond.project.description ?? '',
    },
    ...priceAdditionalItems,
    {
      label: 'summary-price',
      tooltip: 'summary-price-tooltip',
      getValue: bond => getBondPremiumValueBlock(bond),
    },
  ],
}

const tendersBlocks: SummaryBlockParam[] = [
  tendersClientBlock,
  tendersProjectBlock,
  beneficiaryBlock,
  tendersPriceBlock,
]

const tobacconistBlocksWithoutBirthPlace: SummaryBlockParam[] = [
  tobacconistClientBlock,
  tobacconistSimplePrincipalBlock,
  beneficiaryBlock,
  tobacconistPriceBlock,
]

const tobacconistBlocksWithBirthPlace: SummaryBlockParam[] = [
  tobacconistClientBlock,
  tobacconistPrincipalBlockWithBirthPlace,
  beneficiaryBlock,
  tobacconistPriceBlock,
]

const unaTantumBlocks: SummaryBlockParam[] = [
  tobacconistClientBlock,
  tobacconistUnaTantumPrincipalBlock,
  beneficiaryBlock,
  tobacconistPriceBlock,
]

const params: Record<BondType, SummaryBlockParam[]> = {
  BID: tendersBlocks,
  PERFORMANCE: tendersBlocks,
  LIS: tobacconistBlocksWithoutBirthPlace,
  LOTTO: tobacconistBlocksWithBirthPlace,
  LOTTERY: tobacconistBlocksWithoutBirthPlace,
  TAX_STAMPS: tobacconistBlocksWithoutBirthPlace,
  UNA_TANTUM: unaTantumBlocks,
  NINE_YEAR: tobacconistBlocksWithBirthPlace,
}

export default params
