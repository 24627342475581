import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { BelowDesktop, Desktop, Nav, LanguageSelector, Logo } from '@gsp/gusto-front-common'

import { intermediariesLanguages } from '../../../i18n/i18n'

import Login from './Login/Login'

import './Header.scss'

const Header = () => {
  const [isBurgerToggled, setIsBurgerToggled] = useState(false)
  const { acceptedLanguages = ['en'] } = intermediariesLanguages

  return (
    <>
      <header data-testid="header" className="header">
        <div className="left_part">
          <Link to="/">
            <Logo />
          </Link>
          <div className="header__disclaimer-container">
            <FormattedMessage id="allianz-trade-header-disclaimer" tagName="p" />
          </div>
        </div>
        <div className="right_part">
          <nav className="header__navigation">
            <Desktop>
              <Login />
            </Desktop>
          </nav>
          <nav className="header__navigation">
            <Desktop>
              <Nav id="phone-contact" />
            </Desktop>
            <BelowDesktop>
              <div
                data-testid="header-burger"
                onClick={() => {
                  setIsBurgerToggled(!isBurgerToggled)
                }}
                className={`header__burger ${isBurgerToggled ? 'header__burger--isCross' : ''}`}
              >
                <div className={`header__burger-stripe ${isBurgerToggled ? 'header__burger-stripe--isCross' : ''}`} />
              </div>
            </BelowDesktop>
          </nav>
          <div>
            <Desktop>
              <LanguageSelector acceptedLanguages={acceptedLanguages} />
            </Desktop>
          </div>
        </div>
      </header>
      <hr className="header__separator" />
      <div
        data-testid="header-mobile-nav"
        className={`header__mobile-nav ${
          isBurgerToggled ? 'header__mobile-nav--toggled' : 'header__mobile-nav--hidden'
        }`}
      >
        <Login />
        <Nav id="phone-contact" />
      </div>
      {isBurgerToggled ? <hr className="header__separator" /> : ''}
    </>
  )
}

export default Header
