import { ProjectInformationState } from './ProjectInformation'

type ProjectInformationAction =
  | {
      type: 'SET_PROJECT_AMOUNT'
      payload: {
        value: string
        validateAmount: (value: number | null) => boolean
      }
    }
  | {
      type: 'SET_DATES'
      payload: {
        startDate: Date | null
        endDate: Date | null
        isDateValid: boolean
      }
    }
  | {
      type: 'SET_PROJECT_FIELD'
      payload: {
        key: string
        value: string
      }
    }

export default function projectInformationReducer(
  state: ProjectInformationState,
  action: ProjectInformationAction
): ProjectInformationState {
  const newState = { ...state }
  switch (action.type) {
    case 'SET_PROJECT_AMOUNT':
      newState.amount = Number(action.payload.value)
      newState.isAmountValid = action.payload.validateAmount(newState.amount)
      break
    case 'SET_DATES':
      newState.isDateValid = action.payload.isDateValid
      if (newState.isDateValid) {
        newState.startDate = action.payload.startDate
        newState.endDate = action.payload.endDate
      }
      break
    case 'SET_PROJECT_FIELD':
      newState[action.payload.key] = action.payload.value
      break
  }

  return newState
}
