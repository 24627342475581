import React, { useContext, useEffect, useReducer, useState } from 'react'
import { Dropdown, Input, Project, StepComponent, DateInput, LanguageContext } from '@gsp/gusto-front-common'
import { Headline } from '@myeh/design-system'
import { FormattedMessage } from 'react-intl'
import _ from 'lodash'

import BondContext from '../../../../models/BondContext'
import Client from '../../../../models/Client'

import clientInformationParameters from './clientInformationParameters'
import reducer from './reducer'

import './ClientInformation.scss'

interface ClientInformationComponent extends StepComponent {
  readonly: boolean
}

export interface ClientInformationState {
  client: Client
  project: Project
}

const ClientInformationField = ({
  keyName,
  value,
  onChange,
  isRequired,
  readonly,
  objectPath,
  maxLength = 40,
  maxWidthInPercentage = 50,
  isCountryDropdown = false,
  isDateField = false,
}) => {
  const { userLang } = useContext(LanguageContext)
  const [initialValue, setInitialValue] = useState(value)
  useEffect(() => setInitialValue(undefined), [])
  return (
    <div className="client-details-field" style={{ width: `${maxWidthInPercentage}%` }}>
      {isCountryDropdown ? (
        <Dropdown
          labelKey={`client-field-${keyName}`}
          items={[{ code: 'IT', labelKey: 'country-it' }]}
          onChange={value => onChange(value, objectPath)}
          mandatory={isRequired}
          disabled={readonly}
        />
      ) : isDateField ? (
        <DateInput
          initialValue={initialValue}
          dataTestid={keyName}
          labelKey={`client-field-${keyName}`}
          locale={userLang === 'it' ? 'it' : 'enGB'}
          onChange={value => onChange(value, objectPath)}
          isRequired={isRequired}
          disabled={readonly}
          name={keyName}
        />
      ) : (
        <Input
          dataTestid={keyName}
          labelKey={`client-field-${keyName}`}
          value={value}
          changeValue={value => onChange(value, objectPath)}
          isRequired={isRequired}
          maxLength={maxLength}
          disabled={readonly}
          name={keyName}
          forceUppercase
        />
      )}
    </div>
  )
}

const ClientInformation = ({ setStepCompleted, readonly = false }: ClientInformationComponent) => {
  const { bond, setBond } = useContext(BondContext)
  const [state, dispatch] = useReducer(reducer, {}, () => {
    const state: ClientInformationState = {
      client: {
        principalFiscalCode:
          bond.company?.localIdentifiers?.find(
            ({ countryCode, idTypeCode }) => countryCode === 'IT' && idTypeCode === 'COFIS'
          )?.idValue ?? '',
        principalVatNumber:
          bond.company?.localIdentifiers?.find(
            ({ countryCode, idTypeCode }) => countryCode === 'IT' && idTypeCode === 'TVAIT'
          )?.idValue ?? '',
        companyName: bond.company?.companyName ?? '',
        companyId: bond.company?.companyId ?? '',
        ...bond.client,
        principalAddress: {
          town: bond.company?.address.town ?? '',
          countryCode: bond.company?.address.countryCode ?? 'IT',
          stateCode: bond.company?.address.stateCode ?? '',
          postCode: bond.company?.address.postCode ?? '',
          streetNumber: bond.company?.address.streetNumber ?? '',
          streetName: bond.company?.address.streetName ?? '',
          ...bond.client?.principalAddress,
        },
      },
      project: {
        description: bond.project.description,
        amount: bond.project.amount ?? null,
      },
    }

    if (bond.bondType === 'UNA_TANTUM') {
      state.client.privateAddress = {
        countryCode: 'IT',
        town: '',
        stateCode: '',
        streetName: '',
        postCode: '',
        streetNumber: '',
        ...bond.client?.privateAddress,
      }
    }

    return state
  })

  const updateClientField = (value, fieldName) => {
    dispatch({ type: 'UPDATE_CLIENT_FIELD', payload: { value, fieldName } })
  }

  useEffect(() => {
    setBond({
      ...bond,
      client: {
        ...bond.client,
        ...state.client,
      },
      project: {
        ...bond.project,
        ...state.project,
      },
    })
  }, [state])

  useEffect(() => {
    setStepCompleted(
      clientInformationParameters[bond.bondType].fields.every(fieldBlock => {
        return fieldBlock.fields.every(
          field => !field.mandatory || _.get(state.client, field.objectPath ?? field.keyName)
        )
      })
    )
  }, [state])

  return (
    <div className="bond-request-form client-information" data-testid="client-information-wrapper">
      <div className="client-details-form">
        {clientInformationParameters[bond.bondType].fields.map(fieldBlock => {
          return (
            <div key={`block-${fieldBlock.titleKey ?? fieldBlock.fields[0].keyName}`}>
              {fieldBlock.titleKey && (
                <Headline as="h4">
                  <FormattedMessage id={`client-block-title-${fieldBlock.titleKey}`} />
                </Headline>
              )}
              <div className="client-details-block">
                {fieldBlock.fields.map(field => {
                  return (
                    <ClientInformationField
                      key={field.keyName}
                      isCountryDropdown={field.isCountryDropdown}
                      keyName={field.keyName}
                      value={_.get(state.client, field.objectPath ?? field.keyName)}
                      objectPath={field.objectPath ?? field.keyName}
                      isRequired={field.mandatory}
                      maxWidthInPercentage={field.widthPercentage}
                      onChange={updateClientField}
                      readonly={readonly || field.readonly}
                      isDateField={field.isDateField}
                    />
                  )
                })}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default ClientInformation
