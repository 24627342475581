import { FetchOptions, safeGet, safePost, safePut, UNAUTHORIZED } from '@gsp/gusto-front-common'
import { connectionHandler } from '../App'

const authenticatedFetch = async (method: string, url: string, options?: FetchOptions | undefined): Promise<any> => {
  try {
    let response: Response
    switch (method) {
      case 'PUT':
        response = await safePut(url, options)
        break
      case 'POST':
        response = await safePost(url, options)
        break
      case 'GET':
        response = await safeGet(url, options)
        break
      default:
        return Promise.reject('Method type not implemented')
    }
    if (response?.status === 401) {
      connectionHandler.setUserHasBeenDisconnected(true)
      return Promise.reject(UNAUTHORIZED)
    }
    return response
  } catch (error) {
    if (error === UNAUTHORIZED) {
      connectionHandler.setUserHasBeenDisconnected(true)
    }
    return Promise.reject(error)
  }
}

const authenticatedPut = (url: string, options?: FetchOptions | undefined) => authenticatedFetch('PUT', url, options)

const authenticatedPost = (url: string, options?: FetchOptions | undefined) => authenticatedFetch('POST', url, options)

const authenticatedGet = (url: string, options?: FetchOptions | undefined) => authenticatedFetch('GET', url, options)

export { authenticatedPut, authenticatedPost, authenticatedGet }
