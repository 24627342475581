import { isPositive } from './bondDetailsParameters'
import { QuotationDetailState } from './QuotationDetail'

export const emptyDropdownChoice = {
  code: '',
  labelKey: '',
  dontTranslate: true,
}

type Action =
  | {
      type: 'SET_AMOUNT'
      payload: string
    }
  | {
      type: 'SET_DURATION'
      payload: {
        stringedDuration: string
        validateDuration: (value: number) => boolean
      }
    }
  | {
      type: 'SET_DATES'
      payload: {
        startDate: Date | null
        endDate: Date | null
        isDateValid: boolean
      }
    }

function reducer(state: QuotationDetailState, action: Action): QuotationDetailState {
  const newState = { ...state }
  switch (action.type) {
    case 'SET_AMOUNT':
      newState.amount = Number(action.payload)
      newState.isAmountValid = isPositive(newState.amount)
      break
    case 'SET_DURATION':
      newState.duration = Number(action.payload.stringedDuration)
      newState.isDurationValid = action.payload.validateDuration(newState.duration)
      break
    case 'SET_DATES':
      newState.startDate = action.payload.startDate
      newState.endDate = action.payload.endDate
      newState.isDateValid = action.payload.isDateValid
      break
  }
  return newState
}

export default reducer
