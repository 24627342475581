import React, { useCallback, useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import { StepComponent } from '@gsp/gusto-front-common'

import IQuotationSharedContext from '../../../../models/IQuotationSharedContext'

import '../QuotationType/QuotationType.css'
import TypeChoiceLayout from '../TypeChoiceLayout/TypeChoiceLayout'
import BondContext from '../../../../models/BondContext'
import IntermediaryType from '../../../../models/IntermediaryType'

const UserType = ({ goToNextStep, setStepCompleted }: StepComponent<IQuotationSharedContext>) => {
  const { bond, setBond } = useContext(BondContext)
  const selectUserType = useCallback(
    (userType: string) => {
      setBond({
        ...bond,
        intermediaryType: userType as IntermediaryType,
      })
      setStepCompleted(true)
      goToNextStep()
    },
    [bond, setBond, setStepCompleted, goToNextStep]
  )

  const choices = [
    {
      i18nKeyButton: 'user-type-tenders',
      i18nKeyDescription: 'user-type-tenders-description',
      choiceToReturn: 'TENDERS',
    },
    {
      i18nKeyButton: 'user-type-tobacco',
      i18nKeyDescription: 'user-type-tobacco-description',
      choiceToReturn: 'TOBACCO',
    },
  ]

  return (
    <TypeChoiceLayout
      choices={choices}
      callback={selectUserType}
      layoutTitleComponent={
        <FormattedMessage id="quotation-user-type-title" data-testid="preload-quotation-type-title" />
      }
    />
  )
}

export default UserType
