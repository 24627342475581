import { IRadioGroupOption, RadioGroup } from '@gsp/gusto-front-common'
import React from 'react'
import { Col, Row } from 'react-bootstrap'

const authenticationTypeOptions: IRadioGroupOption[] = [
  {
    key: 'beneficiary-additional-digital',
    value: 'NORMAL',
  },
  {
    key: 'beneficiary-additional-certified',
    value: 'SELF_CERTIFIED',
  },
]

const signatureFormatOptions: IRadioGroupOption[] = [
  {
    key: 'beneficiary-additional-signature-format-pdf',
    value: 'PES',
  },
  {
    key: 'beneficiary-additional-signature-format-p7m',
    value: 'CES',
  },
]

export interface ISignatureInformation {
  authenticationType: string
  digitalSignatureFormat: string
  onAuthenticationTypeChange: (value: string) => void
  onDigitalSignatureFormatChange: (value: string) => void
}

export default function SignatureInformation({
  authenticationType,
  digitalSignatureFormat,
  onAuthenticationTypeChange,
  onDigitalSignatureFormatChange,
}: ISignatureInformation) {
  return (
    <Row>
      <Col sm={6}>
        <div className="signature-information-field">
          <RadioGroup
            selectedValue={authenticationType}
            onChange={onAuthenticationTypeChange}
            titleKey="beneficiary-additional-signature-type"
            dataTestid="beneficiary-additional-signature-type"
            options={authenticationTypeOptions}
            name="authenticationType"
            direction="column"
            disabled={false}
            labelSize="small"
          />
        </div>
      </Col>
      <Col sm={6}>
        <div className="signature-information-field">
          <RadioGroup
            selectedValue={digitalSignatureFormat}
            onChange={onDigitalSignatureFormatChange}
            titleKey="beneficiary-additional-signature-format"
            dataTestid="beneficiary-additional-signature-format"
            options={signatureFormatOptions}
            name="signatureFormat"
            direction="column"
            disabled={false}
            labelSize="small"
          />
        </div>
      </Col>
    </Row>
  )
}
