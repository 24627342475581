import React, { ReactElement } from 'react'
import TypeChoiceButton from './TypeChoiceButton/TypeChoiceButton'

import './TypeChoiceLayout.scss'

export interface TypeChoice {
  i18nKeyButton: string
  i18nKeyDescription: string
  choiceToReturn: string
}

const TypeChoiceLayout = (props: {
  choices: Array<TypeChoice>
  callback: (choice: string) => void
  layoutTitleComponent?: ReactElement
  footerComponent?: ReactElement
}) => {
  let title = null
  if (props.layoutTitleComponent) {
    title = <div className="choice-type__title">{props.layoutTitleComponent}</div>
  }

  let columnNumberModifier = 'two-columns'
  const cssModifiers = ['left', 'right']
  if (props.choices.length !== 2) {
    columnNumberModifier = 'three-columns'
    cssModifiers.splice(1, 0, 'center')
  }

  return (
    <>
      {title}
      <div className={`choice-type--${columnNumberModifier}`}>
        {props.choices.map((choice, index) => {
          return (
            <TypeChoiceButton
              key={choice.choiceToReturn}
              {...choice}
              cssModifier={cssModifiers[index % cssModifiers.length]}
              callback={props.callback}
            />
          )
        })}
      </div>
      {props.footerComponent}
    </>
  )
}

export default TypeChoiceLayout
