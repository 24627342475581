import BondRequestPayload from '../models/BondRequestPayload'
import CreateBondRequestResponse from '../models/CreateBondRequestResponse'

import apiUrls from './apiUrls'
import { authenticatedGet, authenticatedPost, authenticatedPut } from './authenticatedFetch'
import BondRequestResponse from '../models/BondRequestResponse'

export const updateBondRequest = (
  bondRequestId: string,
  bondRequestPayload: BondRequestPayload,
  locale: string
): Promise<CreateBondRequestResponse> => {
  return authenticatedPut(apiUrls.bondRequest(bondRequestId), {
    payload: bondRequestPayload,
    headerKeyValues: {
      'Accept-Language': locale,
    },
  }) as Promise<CreateBondRequestResponse>
}

export const createBondRequest = (
  bondRequestPayload: BondRequestPayload,
  locale: string
): Promise<CreateBondRequestResponse> => {
  return authenticatedPost(apiUrls.bondRequests(), {
    payload: bondRequestPayload,
    headerKeyValues: {
      'Accept-Language': locale,
    },
  }) as Promise<CreateBondRequestResponse>
}

export const getBondRequest = (bondRequestId: string): Promise<BondRequestResponse> => {
  return authenticatedGet(apiUrls.bondRequest(bondRequestId)) as Promise<BondRequestResponse>
}

export const getBondRequestAttachment = async (bondRequestId: string, attachmentTypeCode: string): Promise<string> => {
  return authenticatedGet(apiUrls.bondRequestAttachment(bondRequestId, attachmentTypeCode), {
    responseHandler: (statusCode, response) => {
      return response.headers.get('Location')
    },
  })
}
