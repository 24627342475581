import { FormattedMessage } from 'react-intl'
import React from 'react'

const QuotationTypeFooter = () => {
  return (
    <div className="quotation-type__not-found">
      <div>
        <FormattedMessage id="quotation-estimation-not-found" />
      </div>
      <a className="quotation-type__link" target="blank" href="https://www.allianz-trade.com/it_IT/contattaci.html">
        <svg
          className="quotation-type__arrow"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fillRule="evenodd"
          clipRule="evenodd"
        >
          <path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z" />
        </svg>

        <FormattedMessage id="quotation-estimation-another-type" />
      </a>
    </div>
  )
}

export default QuotationTypeFooter
