import React, { useContext } from 'react'
import { StepComponent } from '@gsp/gusto-front-common'

import { buildBondRequest } from '../../../../services/bondRequestMapper'

import BondContext from '../../../../models/BondContext'
import BondRequestResult from './BondRequestResult/BondRequestResult'

const BondRequestStep = ({ setStepCompleted }: StepComponent) => {
  const { bond } = useContext(BondContext)
  const props = {
    bond,
    bondRequest: buildBondRequest(bond),
    callback: () => setStepCompleted(true),
  }

  return <BondRequestResult {...props} />
}

export default BondRequestStep
