import { DateInput, Dropdown, DropdownItem, Input, LanguageContext } from '@gsp/gusto-front-common'
import { FormField, Icon, Popover } from '@myeh/design-system'
import React, { useCallback, useEffect, useReducer } from 'react'
import { useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import BondContext from '../../../../models/BondContext'
import tobacconistQuotationDatesParameters from './tobacconistQuotationDatesParameters'
import tobacconistQuotationDatesReducer, { emptyDropdownChoice } from './tobacconistQuotationDatesReducer'

import './TobacconistQuotationDates.scss'

export interface TobacconistQuotationDatesState {
  startDate?: Date | null
  endDate?: Date | null
  initialStartDate?: Date | null
  initialEndDate?: Date | null
  availableYears: DropdownItem[]
  error: string | null
}

export interface TobacconistQuotationDatesProps {
  createDates?: boolean
  oldDuration?: number
  setDates: (startDate: Date | null, endDate: Date | null, valid: boolean) => void
}

const TobacconistQuotationDates = ({ oldDuration, setDates, createDates }: TobacconistQuotationDatesProps) => {
  const { bond } = useContext(BondContext)
  const parameters = tobacconistQuotationDatesParameters[bond.bondType]
  const [state, dispatch] = useReducer(tobacconistQuotationDatesReducer, {
    startDate: createDates ? null : bond.startDate,
    endDate: createDates ? null : bond.endDate,
    initialStartDate: createDates ? null : bond.startDate,
    initialEndDate: createDates ? null : bond.endDate,
    availableYears: [emptyDropdownChoice],
    error: null,
  })
  const { userLang } = useContext(LanguageContext)

  const handleStartDateChange = useCallback(
    (value: Date | null) =>
      dispatch({
        type: 'SET_START_DATE',
        payload: {
          value,
          validateDates: parameters.validateDates,
          bondType: bond.bondType,
          oldDuration,
          i18nDatesErrorKey: parameters.i18nDatesErrorKey,
        },
      }),
    [dispatch, parameters.validateDates, bond.bondType, oldDuration, parameters.i18nDatesErrorKey]
  )

  const handleEndDateChange = useCallback(
    (value: Date | null) =>
      dispatch({
        type: 'SET_END_DATE',
        payload: {
          value,
          validateDates: parameters.validateDates,
          oldDuration,
          i18nDatesErrorKey: parameters.i18nDatesErrorKey,
        },
      }),
    [dispatch, parameters.validateDates, oldDuration, parameters.i18nDatesErrorKey]
  )

  useEffect(() => {
    dispatch({
      type: 'INITIALIZE_DATES',
      payload: {
        getDefaultEndDate: parameters.getDefaultEndDate,
        validateDates: parameters.validateDates,
        bondType: bond.bondType,
        oldDuration,
        i18nDatesErrorKey: parameters.i18nDatesErrorKey,
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (state.startDate !== undefined && state.endDate !== undefined) {
      setDates(state.startDate, state.endDate, !state.error)
    }
  }, [state.startDate, state.endDate])

  const tooltip = (
    <Popover trigger="hover" direction="top">
      <Popover.Toggle
        aria-label="Trigger by hover"
        style={{
          cursor: 'pointer',
        }}
      >
        <Icon name="info-circle-o" size="small" />
      </Popover.Toggle>
      <Popover.Content css={{}}>
        <FormattedMessage id={parameters.i18nDatesErrorKey} />
      </Popover.Content>
    </Popover>
  )

  let expectedEndDateField = (
    <DateInput
      labelKey="quotation-expected-end-date"
      initialValue={state.initialEndDate ?? ''}
      onChange={handleEndDateChange}
      defaultError={state.error ?? ''}
      isRequired
      validateBeforeFirstBlur
      appendix={createDates ? tooltip : undefined}
      locale={userLang === 'it' ? 'it' : 'enGB'}
      name="quotation-end-date"
    />
  )

  if (bond.bondType === 'TAX_STAMPS') {
    expectedEndDateField = (
      <>
        <div className={`tax-stamps-end-date${createDates ? ' tax-stamps-end-date-with-tooltip' : ''}`}>
          <Input value="31/05" disabled labelKey="quotation-expected-end-date" isRequired />
          <Dropdown
            items={state.availableYears}
            onChange={newEndDate => handleEndDateChange(new Date(newEndDate))}
            value={createDates ? undefined : state.endDate?.toISOString()}
            hasError={!!state.error}
          />
          {createDates && tooltip}
        </div>
        {state.error && (
          <FormField.Error>
            <FormattedMessage id={state.error} />
          </FormField.Error>
        )}
      </>
    )
  }

  return (
    <>
      <DateInput
        labelKey="quotation-start-date"
        initialValue={state.initialStartDate ?? ''}
        onChange={handleStartDateChange}
        isRequired
        validateBeforeFirstBlur
        locale={userLang === 'it' ? 'it' : 'enGB'}
        name="quotation-start-date"
      />
      {expectedEndDateField}
    </>
  )
}

export default TobacconistQuotationDates
