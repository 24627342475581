import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Col, Row } from 'react-bootstrap'
import { Button, durationUnitToString, LocaleFormatter } from '@gsp/gusto-front-common'

import IQuotationData from '../../../../models/IQuotationData'

const isValid = (quotationData: IQuotationData) =>
  quotationData.quotationState === 'DRF' ||
  quotationData.quotationState === 'ISS' ||
  quotationData.quotationState === 'ACP'

interface QuotationDataCardProps {
  quotation: IQuotationData
  localeFormatter: LocaleFormatter
  createBond: (quotation: IQuotationData) => void
  updateBond: (quotation: IQuotationData) => void
  subscribeBond: (quotation: IQuotationData) => void
  viewBond: (quotation: IQuotationData) => void
}

const QuotationDataCard = ({
  quotation,
  localeFormatter,
  createBond,
  updateBond,
  subscribeBond,
  viewBond,
}: QuotationDataCardProps) => {
  const intl = useIntl()
  return (
    <div className="card">
      <div className="card-body">
        <Row className="pb-1">
          <Col className="p-0" lg={9}>
            <h4 className="card-title">{quotation.companyName}</h4>
          </Col>
          <Col className="p-0 quotation-date" lg={3}>
            <FormattedMessage
              id="quotation-list-date"
              values={{
                date: new Date(quotation.receivedTimestamp).toLocaleDateString('it-IT'),
              }}
            />
          </Col>
        </Row>
        <Row className="quotation-list-details">
          <Col
            md={2}
            className={
              isValid(quotation) ? 'quotation-list-status-ok details-flex' : 'quotation-list-status-ko details-flex'
            }
          >
            <Row>
              <FormattedMessage
                id="quotation-list-status"
                values={{
                  status: intl.formatMessage({
                    id: `bond-status-${quotation.status ?? ''}`.toLowerCase(),
                  }),
                }}
              />
            </Row>
            <Row>
              <FormattedMessage
                id="quotation-list-bond-duration"
                values={{
                  duration: durationUnitToString(quotation.bondDuration, intl),
                }}
              />
            </Row>
          </Col>
          <Col>
            <Row>
              <FormattedMessage id={`quotation-type-${quotation.bondType && quotation.bondType.toLowerCase()}`} />
            </Row>
            <Row>
              {quotation.bondAmount ? (
                <FormattedMessage
                  id="quotation-list-bond-amount"
                  values={{
                    amount: localeFormatter.formatCurrency(quotation.bondAmount.value, 'EUR'),
                  }}
                />
              ) : null}
            </Row>
            <Row>
              {quotation.grossPremiumAmount && (
                <FormattedMessage
                  id="quotation-list-gross-premium-amount"
                  values={{
                    amount: localeFormatter.formatCurrency(quotation.grossPremiumAmount.value, 'EUR'),
                  }}
                />
              )}
            </Row>
          </Col>
          {quotation.quotationState === 'ISS' && (
            <Col className="quotation-action">
              <Button i18nKey="quotation-list-button-create-bond" onClick={() => createBond(quotation)} />
            </Col>
          )}
          {quotation.quotationState === 'DRF' && (
            <>
              <Col className="quotation-action">
                <Button i18nKey="quotation-list-button-update-bond" onClick={() => updateBond(quotation)} />
              </Col>
              <Col className="quotation-action">
                <Button i18nKey="quotation-list-button-subscribe-bond" onClick={() => subscribeBond(quotation)} />
              </Col>
            </>
          )}
          {(quotation.quotationState === 'ACP' || quotation.quotationState === 'FIN') && (
            <Col className="quotation-action">
              <Button i18nKey="quotation-list-button-get-bond" onClick={() => viewBond(quotation)} />
            </Col>
          )}
        </Row>
      </div>
    </div>
  )
}

export default QuotationDataCard
