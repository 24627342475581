import React, { useCallback, useEffect, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from '@myeh/design-system'
import {
  BrowserNotSupported,
  GDPRCookieBanner,
  isIE11,
  IUserData,
  Modal,
  UserContext,
  UserType,
} from '@gsp/gusto-front-common'

import apiUrls, { baseApiUrl } from './services/apiUrls'

import IntermediaryDashboard from './components/Dashboard/Dashboard'
import { CookieContextProvider } from './components/context/CookieContextProvider'
import { LanguageContextProvider } from './components/context/LanguageContextProvider'
import BondContext, { initBond } from './models/BondContext'
import Bond from './models/Bond'

import '../node_modules/@myeh/design-system/dist/style.css'
import '../node_modules/@myeh/design-system/dist/fonts.css'
import '../node_modules/@myeh/design-system/dist/icons/style.css'
import './App.scss'
import AdobeAnalytics from './components/AdobeAnalytics/AdobeAnalytics'

// @ts-ignore
window.IS_MOCKED = window.Cypress || process.env.REACT_APP_IS_MOCKED === 'true'

const loginUrl = `${baseApiUrl}/idp-login`

interface AppState {
  bond: Bond
  userData: IUserData
  ready: boolean
}

export const connectionHandler = {
  setUserHasBeenDisconnected: (value: boolean) => {
    /* this function is defined to the one of the App Component, and
     * is used whenever a request returns a 401 HTTP code
     */
  },
}

export default function App() {
  const [appState, setAppState] = useState<AppState>({
    bond: initBond('BID'),
    userData: {
      authenticated: false,
      isIntermediary: true,
      familyName: '',
      givenName: '',
      id: '',
      login: '',
    },
    ready: false,
  })
  const [userHasBeenDisconnected, setUserHasBeenDisconnected] = useState(false)

  useEffect(() => {
    const redirectToMCEL = () => {
      const url =
        window.location.href.includes('allianz-trade.it') || !window.location.href.includes('intermediaries')
          ? `${baseApiUrl}/revoke-logout`
          : window.location.href.replace('intermediaries', 'mcel')
      window.location.replace(url)
    }

    const setUserData = async () => {
      const raw_response = await fetch(apiUrls.idpUserData(), {
        credentials: 'include',
      })
      const response = await raw_response.json()
      if (response.userType !== 'INTERMEDIARY') {
        redirectToMCEL()
      } else {
        setAppState(state => ({
          ...state,
          userData: {
            authenticated: Boolean(response.name),
            userType: UserType.ITALIAN_INTERMEDIARY,
            givenName: (response.name?.givenName as string) ?? '',
            familyName: (response.name?.familyName as string) ?? '',
            id: response.id as string,
            isIntermediary: true,
            login: response.login as string,
            phoneNumber: (response.phoneNumber as string) ?? '',
            parentCompanyId: (response.parentCompanyId as string) ?? '',
          },
          ready: true,
        }))
      }
    }

    const checkIfUserIsAuthenticated = async () => {
      const raw_response = await fetch(apiUrls.idpIsAuthenticated(), {
        credentials: 'include',
      })
      const response = await raw_response.text()
      const isAuthenticated = response === 'true'
      if (isAuthenticated) {
        setUserData()
      } else {
        window.location.replace(loginUrl)
      }
    }

    checkIfUserIsAuthenticated()
  }, [])
  const setBond = useCallback((newBond: Bond) => {
    setAppState(s => ({
      ...s,
      bond: newBond,
    }))
  }, [])

  const setUser = useCallback((userData: IUserData) => {
    setAppState(s => ({
      ...s,
      userData,
    }))
  }, [])

  useEffect(() => {
    connectionHandler.setUserHasBeenDisconnected = setUserHasBeenDisconnected
  }, [setUserHasBeenDisconnected])

  return (
    <ThemeProvider>
      <CookieContextProvider>
        <LanguageContextProvider>
          <BrowserRouter>
            <BondContext.Provider value={{ bond: appState.bond, setBond }}>
              <UserContext.Provider
                value={{
                  user: appState.userData,
                  setUser,
                }}
              >
                {isIE11() && <BrowserNotSupported />}
                <AdobeAnalytics />
                <div className={`App ${isIE11() ? 'ie' : ''}`}>
                  <GDPRCookieBanner />
                  <IntermediaryDashboard ready={appState.ready} />
                  <Modal
                    isOpen={userHasBeenDisconnected}
                    titleKey="fetch-disconnected-modal-title"
                    textKey="fetch-disconnected-modal-text"
                    confirmLabelKey="fetch-disconnected-modal-confirm-label"
                    data-testid="disconnected-modal"
                    handleConfirmClick={() => {
                      window.location.replace(loginUrl)
                    }}
                  />
                </div>
              </UserContext.Provider>
            </BondContext.Provider>
          </BrowserRouter>
        </LanguageContextProvider>
      </CookieContextProvider>
    </ThemeProvider>
  )
}
