import { ErrorDescriptor, ManagedError, toDuration } from '@gsp/gusto-front-common'

import IQuotationListResponse from '../models/IQuotationListResponse'
import IQuotationData from '../models/IQuotationData'

import apiUrls from './apiUrls'
import { authenticatedGet } from './authenticatedFetch'
import { getCompany } from './company'
import { getBondRequest } from './bondRequestService'
import { parseBondRequest } from './bondRequestMapper'
import { getBondIntermediaryType } from '../utils/bondUtils'
import Bond from '../models/Bond'
import BondType from '../models/BondType'
import { initBond } from '../models/BondContext'

export const NO_QUOTATIONS: ErrorDescriptor = {
  defaultMessageKey: 'error-fetch-quotations',
}

function getBondStateCode(quotationData: IQuotationData) {
  if (quotationData.quotationState === 'ISS' && quotationData.bondRequestId) {
    return 'DRF'
  }
  return quotationData.quotationState
}

export const loadQuotationBondData = async (
  setBond: (bond: Bond) => void,
  quotationData: IQuotationData,
  successHandler: () => void
) => {
  const company = await getCompany(quotationData.companyId)

  if (company.localIdentifiers) {
    const [identifier, identifierType] = [...company.localIdentifiers]
      .sort((a, b) => a.priorityNumber - b.priorityNumber)
      .map(i => [i.idValue, i.idTypeCode])[0]
    company.identifier = identifier
    company.identifierType = identifierType
  }

  let bond = initBond(quotationData.bondType as unknown as BondType)
  if (quotationData.startDate && quotationData.endDate) {
    bond.startDate = new Date(quotationData.startDate)
    bond.endDate = new Date(quotationData.endDate)
  }

  if (quotationData.bondRequestId) {
    const bondRequestResponse = await getBondRequest(quotationData.bondRequestId)
    bond = {
      ...bond,
      ...parseBondRequest(bondRequestResponse),
    }
  }

  setBond({
    ...bond,
    intermediaryType: getBondIntermediaryType(bond.bondType),
    bondStateCode: getBondStateCode(quotationData),
    bondQuotationRequestId: quotationData.id,
    bondRequestId: quotationData.bondRequestId,
    contractNumber: quotationData.contractNumber,
    extensionNumber: quotationData.extensionNumber,
    amount: quotationData.bondAmount.value,
    duration: toDuration(quotationData.bondDuration),
    grossPremiumAmount: quotationData.grossPremiumAmount ? quotationData.grossPremiumAmount.value : undefined,
    company,
  })
  successHandler()
}

export const fetchQuotationList: (page: number, pageSize: number) => Promise<IQuotationListResponse> = (
  page,
  pageSize
) => {
  return authenticatedGet(apiUrls.quotationsList(page, pageSize), {
    responseHandler: async (status, response) => {
      if (status === 404) {
        throw new ManagedError(NO_QUOTATIONS)
      }

      const quotationListResponse = await response.json()

      const quotationDateWithStatus = quotationListResponse.quotationData.map(data => ({
        ...data,
        quotationState: getBondStateCode(data),
      }))

      return {
        ...quotationListResponse,
        quotationData: quotationDateWithStatus,
      }
    },
  }) as Promise<IQuotationListResponse>
}
