import React from 'react'
import { Helmet } from 'react-helmet'

const AdobeAnalytics = () => {
  return (
    <Helmet>
      <script src={process.env.REACT_APP_ADOBE_LINK} async />
    </Helmet>
  )
}

export default AdobeAnalytics
