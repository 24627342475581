import React from 'react'
import { getBondIntermediaryType } from '../utils/bondUtils'
import Bond from './Bond'
import BondType from './BondType'

export const initBond: (bondType: BondType) => Bond = (bondType: BondType) => {
  return {
    amount: 5000,
    intermediaryType: getBondIntermediaryType(bondType),
    bondType,
    duration: {
      days: 0,
      months: 0,
    },
    project: {
      description: '',
    },
  }
}

const BondContext = React.createContext({
  bond: initBond('BID'),
  setBond: (bond: Bond) => {},
})

export default BondContext
