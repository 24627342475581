import React, { useContext, useCallback } from 'react'
import { StepComponent } from '@gsp/gusto-front-common'

import QuotationTypeFooter from './QuotationTypeFooter/QuotationTypeFooter'
import TypeChoiceLayout from '../TypeChoiceLayout/TypeChoiceLayout'

import IQuotationSharedContext from '../../../../models/IQuotationSharedContext'

import './QuotationType.css'

import BondContext from '../../../../models/BondContext'
import BondType from '../../../../models/BondType'
import { initBond } from '../../../../models/BondContext'
import TenderQuotationChoices from './tenderQuotationChoices.json'
import TobaccoQuotationChoices from './tobaccoQuotationChoices.json'
import { FormattedMessage } from 'react-intl'

const QuotationType = ({ goToNextStep, setStepCompleted }: StepComponent<IQuotationSharedContext>) => {
  const { bond, setBond } = useContext(BondContext)
  const selectBondType = useCallback(
    (bondType: string) => {
      setBond({
        ...initBond(bondType as BondType),
        intermediaryType: bond.intermediaryType,
        company: bond.company,
      })
      setStepCompleted(true)
      goToNextStep()
    },
    [bond, setBond, setStepCompleted, goToNextStep]
  )

  const choices = bond.intermediaryType === 'TOBACCO' ? TobaccoQuotationChoices : TenderQuotationChoices

  return (
    <TypeChoiceLayout
      choices={choices}
      callback={selectBondType}
      layoutTitleComponent={
        <FormattedMessage id="fast-quotation-type-title" data-testid="preload-quotation-type-title" />
      }
      footerComponent={<QuotationTypeFooter />}
    />
  )
}
export default QuotationType
