import { Tender } from '@gsp/gusto-telemat-api-model'
import apiUrls from './apiUrls'

const getTenderDataFromCig = (cig: string): Promise<Tender> => {
  return fetch(apiUrls.telematTenderData(cig))
    .then(res => {
      if (res.status === 404) {
        return Promise.reject('DNF')
      }

      return res.json()
    })
    .catch(error => (error === 'DNF' ? 'DNF' : 'TER')) as Promise<Tender>
}

export default getTenderDataFromCig
