import React from 'react'
import { injectIntl } from 'react-intl'
import { Button, UserContext } from '@gsp/gusto-front-common'
import { baseApiUrl } from '../../../../services/apiUrls'

const Login = () => {
  return (
    <UserContext.Consumer>
      {({ user }) => (
        <div>
          {user.givenName ? (
            <a href={`${baseApiUrl}/revoke-logout`} title="Click to log out">
              <Button
                i18nKey="logout"
                subTitle={`${user.givenName} ${user.familyName ?? ''}`}
                type="header-secondary"
                isVertical={true}
              />
            </a>
          ) : (
            <a href={`${baseApiUrl}/idp-login`} rel="noreferrer">
              <Button i18nKey="login" type="header-secondary" />
            </a>
          )}
        </div>
      )}
    </UserContext.Consumer>
  )
}

export default injectIntl(Login)
