import React, { useContext, useState, useEffect, useCallback } from 'react'
import Form from 'react-bootstrap/Form'
import { Row, Col } from 'react-bootstrap/'
import { DateInput, Input, isValidEmail, LanguageContext, StepComponent } from '@gsp/gusto-front-common'
import { addMonths, startOfDay } from 'date-fns'

import BondContext from '../../../../models/BondContext'
import AdditionalInfo from '../../../../models/AdditionalInfo'

import './AdditionalInformation.css'
import SignatureInformation from '../SignatureInformation/SignatureInformation'

// no empty signature and PEC
const validateInfos = (additionalInfos: AdditionalInfo) => {
  return (
    additionalInfos.digitalSignatureFormat !== '' &&
    additionalInfos.authenticationType !== '' &&
    isValidEmail(additionalInfos.pec!)
  )
}

const AdditionalInformation = ({ setStepCompleted }: StepComponent) => {
  const { bond, setBond } = useContext(BondContext)
  const { userLang } = useContext(LanguageContext)

  const [additionalInfos, setAdditionalInfos] = useState({
    pec: bond.principalDebtorEmail || '',
    digitalSignatureFormat: 'PES',
    authenticationType: 'NORMAL',
    ...bond.additional,
    startDate: startOfDay(new Date()),
  })

  const [hasDateError, setHasDateError] = useState(false)
  const isValid = !hasDateError && validateInfos(additionalInfos)

  const handleDateChange = useCallback(
    (date: Date | null) => {
      if (date) {
        setAdditionalInfos({ ...additionalInfos, startDate: date })
      } else {
        handleDateError()
      }
    },
    [additionalInfos]
  )

  const handleDateError = () => {
    setHasDateError(true)
  }

  const [errorEmail, setErrorEmail] = useState(false)
  const handleEmailChange = useCallback(
    (value: string) => {
      const validateEmail = isValidEmail(value)
      setErrorEmail(!validateEmail)
      setAdditionalInfos({
        ...additionalInfos,
        pec: value,
      })
    },
    [additionalInfos]
  )

  const handleAuthenticationTypeChange = useCallback(
    (value: string) => {
      setAdditionalInfos({
        ...additionalInfos,
        authenticationType: value,
      })
    },
    [additionalInfos]
  )

  const handleSignatureFormatChange = useCallback(
    (value: string) => {
      setAdditionalInfos({
        ...additionalInfos,
        digitalSignatureFormat: value,
      })
    },
    [additionalInfos]
  )

  useEffect(() => {
    setBond({
      ...bond,
      startDate: additionalInfos.startDate,
      additional: {
        pec: additionalInfos.pec,
        authenticationType: additionalInfos.authenticationType,
        digitalSignatureFormat: additionalInfos.digitalSignatureFormat,
      },
    })
    setStepCompleted(isValid)
    // eslint-disable-next-line
  }, [additionalInfos, isValid])

  return (
    <Form
      className="bond-request-form additional-information-form"
      data-testid="intermediary-additional-information-form"
    >
      <Row>
        <Col sm={6}>
          <DateInput
            initialValue={additionalInfos.startDate}
            onChange={handleDateChange}
            labelKey={`beneficiary-additional-presentation-date-${bond.bondType.toLocaleLowerCase()}`}
            locale={userLang === 'it' ? 'it' : 'enGB'}
            defaultError={hasDateError ? 'beneficiary-additional-date-error' : ''}
            onError={handleDateError}
            maxDate={addMonths(Date.now(), 3)}
            minDate={startOfDay(Date.now())}
            isRequired
          />
        </Col>
        <Col sm={6}>
          <Input
            dataTestid="client-pec"
            value={additionalInfos.pec}
            changeValue={handleEmailChange}
            labelKey="beneficiary-additional-client-pec"
            defaultError={errorEmail ? 'invalid-value' : ''}
            isRequired
            forceUppercase
          />
        </Col>
      </Row>
      <SignatureInformation
        authenticationType={additionalInfos.authenticationType}
        digitalSignatureFormat={additionalInfos.digitalSignatureFormat}
        onAuthenticationTypeChange={handleAuthenticationTypeChange}
        onDigitalSignatureFormatChange={handleSignatureFormatChange}
      />
    </Form>
  )
}

export default AdditionalInformation
