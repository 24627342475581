import React, { useContext, useState, useEffect } from 'react'
import Table from 'react-bootstrap/Table'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  Button,
  Country,
  ErrorDescriptor,
  getLocaleFormatter,
  LanguageContext,
  Loader,
  StepComponent,
  TECHNICAL_ERROR,
  UserContext,
} from '@gsp/gusto-front-common'

import { format } from 'date-fns'

import BondContext, { initBond } from '../../../../models/BondContext'
import Bond from '../../../../models/Bond'
import QuotationRequestResponse from '../../../../models/QuotationRequestResponse'
import IQuotationSharedContext from '../../../../models/IQuotationSharedContext'

import { createQuotationRequest } from '../../../../services/quotationRequest'

import './QuotationRequest.scss'

interface SummaryTableProps {
  bond: Bond
  response: QuotationRequestResponse
  localeCode: string
}
const SummaryTable = ({ bond, response, localeCode }: SummaryTableProps) => {
  const intl = useIntl()
  const localeFormatter = getLocaleFormatter(localeCode)

  const summaryLines = [
    {
      label: 'beneficiary-field-addressName1',
      value: bond.company?.companyName ?? '',
    },
    {
      label: 'summary-bond-type',
      value: intl.formatMessage({
        id: `summary-${bond.bondType}`,
      }),
    },
    {
      label: 'summary-project-amount',
      value: localeFormatter.formatCurrency(bond.amount, 'EUR'),
    },
    {
      label: 'intermediary-premium-amount',
      value: <strong>{localeFormatter.formatCurrency(response.grossPremiumAmount, 'EUR')}</strong>,
    },
  ]
  if (bond.intermediaryType === 'TENDERS') {
    summaryLines.splice(2, 0, {
      label: 'intermediary-summary-duration',
      value:
        bond.bondType === 'BID'
          ? `${bond.duration.days} ${intl.formatMessage({
              id: 'intermediary-summary-days',
            })}`
          : `${bond.duration.months} ${intl.formatMessage({
              id: 'intermediary-summary-months',
            })}`,
    })
  }
  if (bond.intermediaryType === 'TOBACCO' && bond.startDate && bond.endDate) {
    summaryLines.splice(2, 0, {
      label: 'quotation-start-date',
      value: format(bond.startDate, 'dd/MM/yyyy'),
    })
    summaryLines.splice(3, 0, {
      label: 'quotation-expected-end-date',
      value: format(bond.endDate, 'dd/MM/yyyy'),
    })
  }

  return (
    <Table size="sm" className="quotation-summary-table">
      <tbody>
        {summaryLines.map(({ label, value }) => (
          <tr key={label}>
            <td className="text-blue">
              <FormattedMessage id={label} />
            </td>
            <td>{value}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

const QuotationRequest = ({ setStepCompleted, restartStepper, context }: StepComponent<IQuotationSharedContext>) => {
  const { bond, setBond } = useContext(BondContext)
  const { localeCode } = useContext(LanguageContext)
  const [error, setError] = useState<ErrorDescriptor | null>(null)
  const [response, setResponse] = useState<QuotationRequestResponse | null>(null)
  const { user } = useContext(UserContext)

  useEffect(() => {
    const { amount, bondType, duration, company } = bond
    if (!company) {
      setError(TECHNICAL_ERROR)
      return
    }

    // intermediary quotation request
    createQuotationRequest(
      {
        amount: {
          currencyCode: 'EUR',
          value: amount,
        },
        bondType,
        brokerIntermediaryCollectingFlag: false,
        companyId: company.companyId,
        beneficiaryCountry: Country.ITA,
        duration,
        startDate: bond.startDate,
        endDate: bond.endDate,
        companyIdentifier: company.identifier,
        companyIdentifierType: company.identifierType,
      },
      'IT'
    )
      .then(resp => {
        setResponse(resp)
        setBond({
          ...initBond(bond.bondType),
          intermediaryType: bond.intermediaryType,
          startDate: bond.startDate,
          endDate: bond.endDate,
          amount: bond.amount,
          duration: bond.duration,
          company,
          bondQuotationRequestId: resp.bondQuotationRequestId,
          contractNumber: String(resp.contractNumber),
          extensionNumber: String(resp.extensionId),
          grossPremiumAmount: resp.grossPremiumAmount,
        })
        setStepCompleted(true)
      })
      .catch((e: ErrorDescriptor | null) => {
        setError(e)
      })
    // eslint-disable-next-line
  }, [])

  return (
    <div className="quotation-result">
      {!response ? (
        noResponseReceived(error, restartStepper)
      ) : (
        <>
          <h3 className="summary-title">
            <FormattedMessage
              id="quotation-summary-intermediary"
              values={{
                name: `${user.givenName ?? ''} ${user.familyName ?? ''}`,
              }}
            />
          </h3>
          <div className="summary-details">
            <FormattedMessage id="quotation-summary-details" />
            {response && <SummaryTable bond={bond} response={response} localeCode={localeCode} />}
            <div className="caption text-blue mb-3">
              <FormattedMessage id="quotation-summary-premium-disclaimer" />
            </div>
            <Button i18nKey="quotation-summary-finish" onClick={context.goToNextStage} isCentered={true} />
          </div>
        </>
      )}
    </div>
  )
}

// request errors manager
const noResponseReceived = (error: ErrorDescriptor | null, restartStepper: () => void) => {
  if (!error) {
    return <Loader isVisible />
  }

  const idMsg = error === TECHNICAL_ERROR ? 'summary-problem' : error.defaultMessageKey

  return (
    <>
      <span className="error-msg">
        <FormattedMessage id={idMsg} />
      </span>
      <div className="error-controls">
        <Button i18nKey="restart" onClick={restartStepper} />
        <Button i18nKey="dashboard" onClick={() => (window.location.href = '/dashboard')} />
      </div>
    </>
  )
}

export default QuotationRequest
