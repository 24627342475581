import BondType from '../../../../models/BondType'
import Beneficiary from '../../../../models/Beneficiary'

const defaultBeneficiaries: {
  [bondType in BondType]?: Beneficiary
} = {
  LIS: {
    addressName1: 'LIS ISTITUTO DI PAGAMENTO S.P.A.',
    addressName2: '',
    street: 'VIA STARO 4',
    streetNumber: '20134',
    postalCode: 'MILANO',
    postalCodeTypeCode: 'MAIN',
    stateCode: 'MI',
    cityName: 'MILANO',
    countryCode: 'IT',
    emailAddress: '',
  },
  LOTTERY: {
    addressName1: 'IGT LOTTERY S.P.A.',
    addressName2: '',
    street: 'VIALE DEL CAMPO BOARIO',
    streetNumber: '56/D',
    postalCode: '00154',
    postalCodeTypeCode: 'MAIN',
    stateCode: 'RM',
    cityName: 'ROMA',
    countryCode: 'IT',
    emailAddress: '',
  },
}

export default defaultBeneficiaries
