import { DateTime, Duration, DurationLikeObject } from 'luxon'
import { format } from 'date-fns'

export function isValidDateRange(startDate: Date | null, endDate: Date | null) {
  return startDate !== null && endDate !== null && startDate < endDate
}

export const isValidRange = (
  startDate: Date | null,
  endDate: Date | null,
  maxDuration: DurationLikeObject,
  minDuration: DurationLikeObject = { day: 0 }
) => {
  if (!isValidDateRange(startDate, endDate)) {
    return false
  }
  const start = DateTime.fromJSDate(startDate as Date).set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  })
  const minimumEndDate = start.plus(Duration.fromDurationLike(minDuration))
  const maximumEndDate = start.plus(Duration.fromDurationLike(maxDuration))
  const end = DateTime.fromJSDate(endDate as Date).set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  })
  return minimumEndDate <= end && end <= maximumEndDate
}

export const addYearToDate = (startDate: Date, yearToAdd: number) => {
  const newDate = new Date(startDate)
  return newDate.setFullYear(startDate.getFullYear() + yearToAdd)
}

export const toISOStringIgnoringUTC = (date: Date) => {
  return format(date, "yyyy-MM-dd'T'HH:mm:ss.SSS")
}
