import React, { FC, ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'
import { InfoText } from '@gsp/gusto-front-common'

import './SummaryGroup.scss'
import { Icon, Popover } from '@myeh/design-system'

export interface ISummaryField {
  label: string
  value?: string | ReactNode
  infoText?: string
  testId?: string
  hiddenLabel?: boolean
  tooltip?: string
}
const SummaryRow: FC<ISummaryField> = ({ testId, label, value, infoText, hiddenLabel, tooltip }) => {
  return (
    <tr data-testid={testId ?? label} className="mb-2">
      <td data-testid={`${testId ?? label}-label`} className="summary__group__label">
        {!hiddenLabel && (
          <span className="summary__group__label__span">
            <FormattedMessage id={label} />
            {tooltip ? (
              <Popover trigger="hover" direction="top">
                <Popover.Toggle
                  aria-label="Trigger by hover"
                  style={{
                    cursor: 'pointer',
                  }}
                >
                  <Icon name="info-circle-o" className="summary__group__tooltip" size="small" />
                </Popover.Toggle>
                <Popover.Content css={{}}>
                  <FormattedMessage id={tooltip} />
                </Popover.Content>
              </Popover>
            ) : null}
          </span>
        )}
      </td>
      {value ? (
        <td data-testid={`${testId ?? label}-value`} className="summary__group__value text-blue bold">
          {value}
          {infoText && (
            <span data-testid={`${testId ?? label}-info-text`} className="summary__group__info">
              <InfoText textKey={infoText} />
            </span>
          )}
        </td>
      ) : null}
    </tr>
  )
}

export interface SummaryGroupProps {
  title: string
  items: ISummaryField[]
  dataTestid?: string
}
const SummaryGroup: FC<SummaryGroupProps> = ({ title, items, dataTestid }) => {
  return (
    <div data-testid={dataTestid || ''} className="summary__group">
      <div data-testid={dataTestid ? `${dataTestid}-title` : ''} className="summary__group__title">
        <FormattedMessage id={title} />
      </div>
      <table data-testid={dataTestid ? `${dataTestid}-table` : ''} className="summary__group__table text-left">
        <tbody>
          {items.map((item, idx) => (
            <SummaryRow key={idx} {...item} />
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default SummaryGroup
