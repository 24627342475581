import { DropdownItem } from '@gsp/gusto-front-common'
import { addYearToDate, isValidRange } from '../../../../utils/dateUtils'

interface TobacconistQuotationDatesParameter {
  getDefaultEndDate: (startDate: Date | null) => number | null
  i18nKeySuffix: string
  validateDates: (startDate: Date | null, endDate: Date | null) => boolean
  i18nDatesErrorKey: string
}

export const getDropdownAvailableYearTaxStampsBond = (
  startDate: Date | null,
  validateDates: (startDate: Date | null, endDate: Date | null) => boolean
) => {
  if (startDate === null) {
    return []
  }
  const availableYears: DropdownItem[] = []
  const testEndDate = new Date(startDate)
  testEndDate.setMonth(4, 31)
  let availableYear = startDate.getFullYear()
  if (startDate.getMonth() > 4 || (startDate.getMonth() === 4 && startDate.getDate() === 31)) {
    testEndDate.setFullYear(++availableYear)
  }
  while (validateDates(startDate, testEndDate)) {
    availableYears.push({
      code: testEndDate.toISOString(),
      labelKey: String(availableYear),
      dontTranslate: true,
    })
    testEndDate.setFullYear(++availableYear)
  }
  return availableYears
}

const tobacconistQuotationDatesParameters: { [key: string]: TobacconistQuotationDatesParameter } = {
  LIS: {
    getDefaultEndDate: startDate => (startDate ? addYearToDate(startDate, 1) : null),
    i18nKeySuffix: 'lis',
    validateDates: (startDate, endDate) => isValidRange(startDate, endDate, { year: 1 }, { year: 1 }),
    i18nDatesErrorKey: 'invalid-duration-year-exact',
  },
  UNA_TANTUM: {
    getDefaultEndDate: startDate => (startDate ? addYearToDate(startDate, 1) : null),
    i18nKeySuffix: 'una-tantum',
    validateDates: (startDate, endDate) => isValidRange(startDate, endDate, { year: 1 }, { year: 1 }),
    i18nDatesErrorKey: 'invalid-duration-year-exact',
  },
  TAX_STAMPS: {
    getDefaultEndDate: startDate =>
      startDate ? new Date(startDate).setFullYear(getTaxStampsDefaultEndYear(startDate), 4, 31) : null,
    i18nKeySuffix: 'tax-stamps',
    validateDates: (startDate, endDate) => {
      if (!isValidRange(startDate, endDate, { year: 2 })) {
        return false
      }
      if (endDate?.getMonth() !== 4 || endDate.getDate() !== 31) {
        return false
      }
      return true
    },
    i18nDatesErrorKey: 'invalid-tax-stamps-end-date',
  },
  LOTTERY: {
    getDefaultEndDate: startDate => (startDate ? addYearToDate(startDate, 1) : null),
    i18nKeySuffix: 'lottery',
    validateDates: (startDate, endDate) => isValidRange(startDate, endDate, { year: 2 }, { day: 1 }),
    i18nDatesErrorKey: 'invalid-duration-max-two-years',
  },
  LOTTO: {
    getDefaultEndDate: startDate => (startDate ? addYearToDate(startDate, 1) : null),
    i18nKeySuffix: 'lotto',
    validateDates: (startDate, endDate) => isValidRange(startDate, endDate, { year: 2 }, { day: 1 }),
    i18nDatesErrorKey: 'invalid-duration-max-two-years',
  },
  NINE_YEAR: {
    getDefaultEndDate: startDate => (startDate ? addYearToDate(startDate, 9) : null),
    i18nKeySuffix: 'nine-year',
    validateDates: (startDate, endDate) => isValidRange(startDate, endDate, { year: 9 }, { day: 1 }),
    i18nDatesErrorKey: 'invalid-duration-max-nine-years',
  },
}

const getTaxStampsDefaultEndYear = (startDate: Date) => {
  if (startDate.getTime() < new Date(startDate.getFullYear(), 4, 31).getTime()) {
    return startDate.getFullYear()
  }
  return startDate.getFullYear() + 1
}

export default tobacconistQuotationDatesParameters
