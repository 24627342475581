import enMessages from './en_UK.json'
import itMessages from './it_IT.json'
import IIntermediariesLocale from './IIntermediariesLocale'
import IntermediariesLanguage from './IntermediariesLanguage'

export const intermediariesLanguages: IIntermediariesLocale = {
  acceptedLanguages: ['it', 'en'],
  defaultLanguage: 'it',
}

const messagesList = {
  en: enMessages,
  it: itMessages,
}

const localeCodes = {
  en: 'en-EN',
  it: 'it-IT',
}

export const getUserLang = (detectedLang: string) => {
  const userLang: IntermediariesLanguage = intermediariesLanguages.acceptedLanguages.includes(
    detectedLang as IntermediariesLanguage
  )
    ? (detectedLang as IntermediariesLanguage)
    : intermediariesLanguages.defaultLanguage

  return userLang
}

export function getLocaleCode(language: IntermediariesLanguage) {
  return localeCodes[language]
}

export function getMessages(language: IntermediariesLanguage) {
  return messagesList[language]
}
