import React from 'react'
import { Button } from '@gsp/gusto-front-common'
import { FormattedMessage } from 'react-intl'

import '../TypeChoiceLayout.scss'

const TypeChoiceButton = (props: {
  i18nKeyButton: string
  i18nKeyDescription: string
  choiceToReturn: string
  cssModifier: string
  callback: (choice: string) => void
}) => {
  const br = () => <br />
  return (
    <div className={`choice-type__option--${props.cssModifier}`}>
      <Button i18nKey={props.i18nKeyButton} type="secondary-big" onClick={() => props.callback(props.choiceToReturn)} />
      <div className={`choice-type__description--${props.cssModifier}`}>
        <FormattedMessage
          id={props.i18nKeyDescription}
          values={{
            br,
          }}
        />
      </div>
    </div>
  )
}

export default TypeChoiceButton
