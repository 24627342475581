import { DateTime } from 'luxon'

import BondType from '../../../../models/BondType'
import { isValidDateRange } from '../../../../utils/dateUtils'
import { TobacconistQuotationDatesState } from './TobacconistQuotationDates'
import { getDropdownAvailableYearTaxStampsBond } from './tobacconistQuotationDatesParameters'

export const emptyDropdownChoice = {
  code: '',
  labelKey: '',
  dontTranslate: true,
}

function validateDuration(startDate: Date, endDate: Date, duration: number) {
  const newDuration = Math.round(DateTime.fromJSDate(endDate).diff(DateTime.fromJSDate(startDate), 'days').days)

  return newDuration === Math.round(duration)
}

function getErrorMessage(
  startDate: Date | null,
  endDate: Date | null,
  validateDates: (startDate: Date | null, endDate: Date | null) => boolean,
  bondError: string,
  duration?: number
): string | null {
  if (!isValidDateRange(startDate, endDate)) {
    return 'invalid-duration-range'
  }
  if (!validateDates(startDate, endDate)) {
    return bondError
  }
  if (duration && !validateDuration(startDate!, endDate!, duration)) {
    return 'invalid-duration-different-duration'
  }
  return null
}

type TobacconistQuotationDatesAction =
  | {
      type: 'INITIALIZE_DATES'
      payload: {
        getDefaultEndDate: (startDate: Date | null) => number | null
        validateDates: (startDate: Date | null, endDate: Date | null) => boolean
        bondType: BondType
        oldDuration?: number
        createDates?: boolean
        i18nDatesErrorKey: string
      }
    }
  | {
      type: 'SET_START_DATE'
      payload: {
        value: Date | null
        validateDates: (startDate: Date | null, endDate: Date | null) => boolean
        bondType: BondType
        oldDuration?: number
        i18nDatesErrorKey: string
      }
    }
  | {
      type: 'SET_END_DATE'
      payload: {
        value: Date | null
        validateDates: (startDate: Date | null, endDate: Date | null) => boolean
        oldDuration?: number
        i18nDatesErrorKey: string
      }
    }

export default function tobacconistQuotationDatesReducer(
  state: TobacconistQuotationDatesState,
  action: TobacconistQuotationDatesAction
): TobacconistQuotationDatesState {
  const newState = { ...state }

  switch (action.type) {
    case 'INITIALIZE_DATES':
      newState.startDate = (!action.payload.createDates && newState.startDate) || new Date()
      newState.initialStartDate = newState.startDate
      const rawEndDate =
        (!action.payload.createDates && newState.endDate) || action.payload.getDefaultEndDate(newState.startDate)
      if (typeof rawEndDate === 'number') {
        newState.endDate = new Date(rawEndDate)
        newState.initialEndDate = newState.endDate
      }
      if (action.payload.bondType === 'TAX_STAMPS') {
        newState.availableYears = getDropdownAvailableYearTaxStampsBond(
          newState.startDate,
          action.payload.validateDates
        )
      }

      newState.error = getErrorMessage(
        newState.startDate,
        newState.endDate!,
        action.payload.validateDates,
        action.payload.i18nDatesErrorKey,
        action.payload.oldDuration
      )
      break
    case 'SET_START_DATE':
      if (!action.payload.value) break
      newState.startDate = action.payload.value
      if (action.payload.bondType === 'TAX_STAMPS' && state.startDate) {
        newState.availableYears = getDropdownAvailableYearTaxStampsBond(
          newState.startDate,
          action.payload.validateDates
        )
        newState.endDate = new Date(newState.availableYears[0].code)
      }
      newState.error = getErrorMessage(
        newState.startDate,
        newState.endDate!,
        action.payload.validateDates,
        action.payload.i18nDatesErrorKey,
        action.payload.oldDuration
      )
      break
    case 'SET_END_DATE':
      if (!action.payload.value) break
      newState.endDate = action.payload.value
      newState.error = getErrorMessage(
        newState.startDate!,
        newState.endDate,
        action.payload.validateDates,
        action.payload.i18nDatesErrorKey,
        action.payload.oldDuration
      )
      break
  }

  return newState
}
