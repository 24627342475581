import { formatDuration, serializeDate, toDuration } from '@gsp/gusto-front-common'

import Bond from '../models/Bond'
import Beneficiary from '../models/Beneficiary'
import BondRequestPayload from '../models/BondRequestPayload'
import IBondRequestProjectData from '../models/IBondRequestProjectData'
import { getBondIntermediaryType } from '../utils/bondUtils'
import BondType from '../models/BondType'

const cigPrefix = ' - CIG '

const getBeneficiaryDataPayload: (beneficiary: Beneficiary) => Beneficiary = (beneficiary: Beneficiary) => ({
  addressName1: beneficiary.addressName1,
  addressName2: beneficiary.addressName2,
  cityName: beneficiary.cityName,
  countryCode: beneficiary.countryCode,
  postalCode: beneficiary.postalCode,
  postalCodeTypeCode: 'MAIN',
  emailAddress: beneficiary.emailAddress,
  street: beneficiary.street,
  streetNumber: beneficiary.streetNumber,
  stateCode: beneficiary.stateCode,
})

const getProjectDataPayload: (bond: Bond) => IBondRequestProjectData = (bond: Bond) => {
  if (!bond.additional || !bond.project) {
    throw new Error('Missing project data')
  }
  return {
    date: serializeDate(bond.additional.startDate),
    startDate: serializeDate(bond.additional.startDate),
    endDate: null,
    description: bond.project.description?.toUpperCase(),
    referenceNumber: bond.project.bidBondNumber,
    tenderLocation: bond.project.location,
    tenderNumber: bond.cig,
    type: 'Contract',
    renewal: bond.project.renewal,
  }
}

export const buildBondRequest: (bond: Bond) => BondRequestPayload = (bond: Bond) => {
  if (!bond.bondQuotationRequestId) {
    throw new Error('Missing bond request ID')
  } else if (!bond.beneficiary) {
    throw new Error('Missing bond request ID')
  } else if (!bond.company) {
    throw new Error('Missing company data')
  } else if (!bond.project) {
    throw new Error('Missing project data')
  } else if (!bond.additional) {
    throw new Error('Missing additional data')
  }

  return {
    amount: { value: bond.amount, currencyCode: 'EUR' },
    authenticationType: bond.additional.authenticationType,
    beneficiaryData: getBeneficiaryDataPayload(bond.beneficiary),
    bondQuotationRequestId: bond.bondQuotationRequestId,
    bondStateCode: bond.bondStateCode,
    bondType: bond.bondType,
    buCode: 'IT',
    client:
      bond.intermediaryType === 'TOBACCO'
        ? {
            ...bond.client,
            principalDateOfBirth: bond.client?.principalDateOfBirth
              ? serializeDate(bond.client.principalDateOfBirth) ?? undefined
              : undefined,
          }
        : undefined,
    companyId: bond.company.companyId,
    contractNumber: bond.contractNumber,
    customGrossPremium: bond.customGrossPremium,
    customGrossPremiumAmount: { value: bond.customGrossPremiumAmount || bond.grossPremiumAmount!, currencyCode: 'EUR' },
    duration: formatDuration(bond.duration),
    endDate: bond.endDate ? serializeDate(bond.endDate) : null,
    extensionNumber: bond.extensionNumber,
    maintenanceDuration: { quantity: 0, unit: 'DD' },
    principalDebtAmount: bond.project.amount
      ? { value: bond.project.amount!, currencyCode: 'EUR' }
      : bond.principalDebtAmount?.value
      ? bond.principalDebtAmount
      : null,
    principalDebtAmountTaxCode: 'EXCL',
    principalDebtorEmail: bond.additional.pec || bond.principalDebtorEmail,
    projectData: getProjectDataPayload(bond),
    signatureType: bond.additional.digitalSignatureFormat,
    startDate: serializeDate(bond.startDate),
  }
}

export const parseBondRequest: (response: BondRequestPayload) => Bond = (response: BondRequestPayload) => {
  const intermediaryType = getBondIntermediaryType(response.bondType as BondType)
  const cigIndex = response.projectData.description.indexOf(cigPrefix)
  const description =
    cigIndex !== -1 ? response.projectData.description.substring(0, cigIndex) : response.projectData.description
  const tenderNumber =
    cigIndex !== -1
      ? response.projectData.description.substring(cigIndex + cigPrefix.length, response.projectData.description.length)
      : ''
  return {
    amount: response.amount.value,
    additional: {
      authenticationType: response.authenticationType,
      digitalSignatureFormat: response.signatureType,
    },
    beneficiary: response.beneficiaryData,
    bondQuotationRequestId: response.bondQuotationRequestId,
    bondStateCode: response.bondStateCode,
    bondType: response.bondType,
    client: response.client
      ? {
          ...response.client,
          principalDateOfBirth: response.client?.principalDateOfBirth
            ? new Date(response.client.principalDateOfBirth)
            : undefined,
        }
      : undefined,
    contractNumber: response.contractNumber,
    company: response.companyId
      ? {
          companyId: response.companyId,
        }
      : undefined,
    duration: response.duration ? toDuration(response.duration) : undefined,
    customGrossPremium: response.customGrossPremium,
    customGrossPremiumAmount: response.customGrossPremiumAmount?.value,
    startDate: response.startDate ? new Date(response.startDate) : undefined,
    endDate: response.endDate ? new Date(response.endDate) : undefined,
    extensionNumber: response.extensionNumber,
    cig: tenderNumber ?? response.projectData.tenderNumber,
    principalDebtAmount: response.principalDebtAmount,
    principalDebtorEmail: response.principalDebtorEmail,
    project: {
      amount: response.projectData.amount?.value || null,
      description,
      location: response.projectData.tenderLocation,
      bidBondNumber: response.projectData.referenceNumber,
      renewal: response.continualInvoicePeriod,
    },
    intermediaryType,
  } as any as Bond
}
