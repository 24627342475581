import React, { useContext, useEffect, useReducer } from 'react'
import { DropdownItem, NumberInput, StepComponent } from '@gsp/gusto-front-common'

import IQuotationSharedContext from '../../../../models/IQuotationSharedContext'

import './QuotationDetail.scss'

import BondContext from '../../../../models/BondContext'
import reducer, { emptyDropdownChoice } from './reducer'
import bondDetailsParameters, { isPositive } from './bondDetailsParameters'
import TobacconistQuotationDates from '../TobacconistQuotationDates/TobacconistQuotationDates'

export interface QuotationDetailState {
  amount: number
  isAmountValid: boolean
  duration?: number
  isDurationValid: boolean
  startDate?: Date | null
  endDate?: Date | null
  isDateValid: boolean
  availableYears: DropdownItem[]
}

const QuotationDetail = ({ setStepCompleted }: StepComponent<IQuotationSharedContext>) => {
  const { bond, setBond } = useContext(BondContext)
  const parameters = bondDetailsParameters[bond.bondType]
  const [state, dispatch] = useReducer(reducer, {}, () => {
    const state: QuotationDetailState = {
      amount: bond.amount,
      isAmountValid: true,
      isDurationValid: true,
      startDate: null,
      endDate: null,
      isDateValid: true,
      availableYears: [emptyDropdownChoice],
    }

    if (bond.intermediaryType === 'TENDERS') {
      state.duration = parameters.defaultDuration
    } else {
      state.startDate = null
      state.endDate = null
    }

    return state
  })
  let dateRangeFields: JSX.Element | null = null
  let durationField: JSX.Element | null = null

  const setDates = (startDate: Date | null, endDate: Date | null, isDateValid: boolean) => {
    dispatch({
      type: 'SET_DATES',
      payload: {
        startDate,
        endDate,
        isDateValid,
      },
    })
  }

  if (bond.intermediaryType === 'TOBACCO') {
    dateRangeFields = <TobacconistQuotationDates setDates={setDates} createDates={true} />
  }

  if (bond.intermediaryType === 'TENDERS') {
    durationField = (
      <NumberInput
        dataTestid="quotation-duration"
        labelKey={`quotation-detail-duration-${parameters.i18nKeySuffix}`}
        value={String(state.duration)}
        changeValue={value =>
          dispatch({
            type: 'SET_DURATION',
            payload: {
              stringedDuration: value,
              validateDuration: parameters.validateDuration,
            },
          })
        }
        isRequired
        defaultError={state.isDurationValid ? '' : parameters.i18nDurationErrorKey}
        validateBeforeFirstBlur
      />
    )
  }

  useEffect(() => {
    let newBond = { ...bond, amount: state.amount }
    if (bond.intermediaryType === 'TENDERS' && parameters.updateBondDuration) {
      const newDuration = parameters.updateBondDuration(bond.duration, state.duration!)
      newBond = { ...newBond, duration: newDuration }
    }
    if (bond.intermediaryType === 'TOBACCO' && state.startDate !== null && state.endDate !== null) {
      newBond = {
        ...newBond,
        startDate: state.startDate,
        endDate: state.endDate,
      }
    }
    setBond({
      ...newBond,
    })
    setStepCompleted(isPositive(state.amount) && parameters.validateDuration(state.duration) && state.isDateValid)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.duration, state.amount, state.startDate, state.endDate])

  return (
    <div data-testid="quotation-detail" className="quotation-detail">
      <NumberInput
        dataTestid="bond-amount"
        labelKey="quotation-detail-amount"
        value={String(state.amount)}
        changeValue={value => dispatch({ type: 'SET_AMOUNT', payload: value })}
        isRequired
        defaultError={state.isAmountValid ? '' : 'invalid-amount'}
        validateBeforeFirstBlur
      />
      {durationField}
      {dateRangeFields}
    </div>
  )
}

export default QuotationDetail
