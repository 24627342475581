import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import BondContext from '../../../../models/BondContext'
import { Col, Row } from 'react-bootstrap'
import { getLocaleFormatter, Input, NumberInput } from '@gsp/gusto-front-common'
import { FormattedMessage } from 'react-intl'
import { MdEdit, MdSave } from 'react-icons/md'

const localeFormatter = getLocaleFormatter('fr-FR')

export const PremiumValue = () => {
  const { bond, setBond } = useContext(BondContext)
  const [editMode, setEditMode] = useState<boolean>(false)
  const [customPremiumAmount, setCustomPremiumAmount] = useState<number | undefined>(
    bond.customGrossPremiumAmount || bond.grossPremiumAmount
  )
  const [error, setError] = useState<boolean>(false)
  const inputRef = useRef<HTMLInputElement>(null)

  const toggleEditMode = useCallback(() => {
    setEditMode(state => !state)
  }, [setEditMode])

  const handleBlur = useCallback(() => {
    setError(false)
    setEditMode(false)
    if (customPremiumAmount && bond?.grossPremiumAmount && customPremiumAmount < bond.grossPremiumAmount) {
      setCustomPremiumAmount(bond.grossPremiumAmount)
      setError(true)
    }
  }, [customPremiumAmount, bond.grossPremiumAmount, setError, setEditMode, setCustomPremiumAmount])

  const handleChange = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      const value = Number(evt.target.value)
      if (!isNaN(value)) {
        setError(false)
        setCustomPremiumAmount(value)
      }
    },
    [setError, setCustomPremiumAmount]
  )

  useEffect(() => {
    if (editMode) {
      inputRef.current?.focus()
    }
  }, [editMode])

  useEffect(() => {
    if (error) {
      setEditMode(true)
    }
  }, [error])

  useEffect(() => {
    if (customPremiumAmount) {
      setBond({
        ...bond,
        customGrossPremium: customPremiumAmount > bond.grossPremiumAmount!,
        customGrossPremiumAmount: customPremiumAmount,
      })
    }
  }, [customPremiumAmount])

  return (
    <Row>
      <Col xs={9} className="screen-only">
        {editMode ? (
          <NumberInput
            ref={inputRef}
            onChange={handleChange}
            onBlur={handleBlur}
            type="number"
            min={customPremiumAmount}
            value={customPremiumAmount?.toString() || ''}
            dataTestid="summary-price-input"
          />
        ) : (
          <Input
            disabled
            dataTestid="summary-price-input-disabled"
            value={localeFormatter.formatCurrency(customPremiumAmount ?? 0, 'EUR')}
          />
        )}
        {error && (
          <span className="label text--error" data-testid="summary-premium-value-error">
            <FormattedMessage id="summary-custom-premium-error" />
            {localeFormatter.formatCurrency(bond.grossPremiumAmount ?? 0, 'EUR')}
          </span>
        )}
      </Col>
      <Col xs={3} className="screen-only edit-icon">
        <span data-testid="summary-price-input-button" onClick={toggleEditMode}>
          {editMode ? <MdSave size="2em" /> : <MdEdit size="2em" />}
        </span>
      </Col>
      <Col xs="12" className="print-only">
        {localeFormatter.formatCurrency(customPremiumAmount ?? 0, 'EUR')}
      </Col>
    </Row>
  )
}
