import { ErrorDescriptor, formatDuration } from '@gsp/gusto-front-common'
import { authenticatedPost } from './authenticatedFetch'
import QuotationRequestResponse, { QuotationReasons } from '../models/QuotationRequestResponse'
import apiUrls from './apiUrls'
import QuotationRequestPayload from '../models/QuotationRequestPayload'
import { toISOStringIgnoringUTC } from '../utils/dateUtils'

export const QUOTATION_REJECTED: ErrorDescriptor = {
  defaultMessageKey: 'summary-bad-grade',
}
export const QUOTATION_SANCTIONNED_COMPANY: ErrorDescriptor = {
  defaultMessageKey: 'summary-sanctionned-company',
}
export const QUOTATION_PENDING: ErrorDescriptor = {
  defaultMessageKey: 'summary-grade-unavailable',
}

const isSanctionnedCompany = (resp: QuotationRequestResponse): boolean => {
  return resp.reasonForResponseCode === QuotationReasons.SANCTION
}

export const createQuotationRequest = (
  {
    amount,
    beneficiaryCountry,
    bondType,
    duration,
    companyId,
    brokerIntermediaryCollectingFlag,
    startDate,
    endDate,
    companyIdentifier,
    companyIdentifierType,
  }: QuotationRequestPayload,
  locale: string
): Promise<QuotationRequestResponse> =>
  authenticatedPost(apiUrls.quotationRequests(), {
    payload: {
      amount,
      beneficiaryCountry,
      bondType,
      brokerIntermediaryCollectingFlag,
      companyId,
      duration: formatDuration(duration),
      startDate: startDate ? toISOStringIgnoringUTC(startDate) : undefined,
      endDate: endDate ? toISOStringIgnoringUTC(endDate) : undefined,
      companyIdentifier,
      companyIdentifierType,
    },
    headerKeyValues: { 'Accept-Language': locale },
    errorMap: {
      REJ: QUOTATION_REJECTED,
      PND: QUOTATION_PENDING,
    },
  }).then((resp: QuotationRequestResponse) => {
    switch (resp.responseCode) {
      case QuotationReasons.REJECTED:
        return isSanctionnedCompany(resp)
          ? Promise.reject(QUOTATION_SANCTIONNED_COMPANY)
          : Promise.reject(QUOTATION_REJECTED)
      case QuotationReasons.PENDING:
        return Promise.reject(QUOTATION_PENDING)
    }
    return Promise.resolve(resp)
  })
