import React, { ReactNode, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Loader } from '@gsp/gusto-front-common'
import Bond from '../../../../../models/Bond'
import CreateBondRequestResponse from '../../../../../models/CreateBondRequestResponse'
import BondRequestPayload from '../../../../../models/BondRequestPayload'
import {
  createBondRequest,
  getBondRequestAttachment,
  updateBondRequest,
} from '../../../../../services/bondRequestService'

import './BondRequestResult.scss'

interface ResultMessageProps {
  bond: Bond
  bondRequestResponse: CreateBondRequestResponse
}

const ResultMessage = ({ bond, bondRequestResponse }: ResultMessageProps) => {
  const companyName: string = (bond.company && bond.company.companyName) || ''
  return (
    <div className="result-message">
      <p>
        <FormattedMessage
          id={`bond-request-result-${bond.bondStateCode === 'DRF' ? 'saved' : 'created'}-1`}
          values={{
            bondNumber: bondRequestResponse.bondNumber,
            b: (...chunks: Array<ReactNode>) => <b>{chunks}</b>,
          }}
        />
      </p>
      <p>
        <FormattedMessage id={`bond-request-result-${bond.bondStateCode === 'DRF' ? 'saved' : 'created'}-2`} />
      </p>
      {bond.bondStateCode !== 'DRF' && (
        <p>
          <FormattedMessage id="bond-request-result-created-3" values={{ companyName }} />
        </p>
      )}
    </div>
  )
}

interface BondRequestResultProps {
  bond: Bond
  bondRequest: BondRequestPayload
  callback: (response: CreateBondRequestResponse) => void
  errorCallback?: (errorResponse: any) => void
}

const BondRequestResult = ({ bond, bondRequest, callback, errorCallback }: BondRequestResultProps) => {
  const sendBondRequest = () => {
    const bondPromise = bond.bondRequestId
      ? updateBondRequest(bond.bondRequestId, bondRequest, 'IT')
      : createBondRequest(bondRequest, 'IT')

    bondPromise
      .then(async resp => {
        if (bond.bondStateCode === 'DRF') {
          const [draftOtherUrl, draftBeneficiary] = await Promise.all([
            getBondRequestAttachment(resp.bondRequestId, 'DRAFT_OTHERS'),
            getBondRequestAttachment(resp.bondRequestId, 'DRAFT_BENEFICIARY'),
          ])
          window.open(draftOtherUrl, '_blank')
          window.open(draftBeneficiary, '_blank')
        }
        setResponse(resp)
        callback(resp)
      })
      .catch(e => {
        setError(e)
        if (errorCallback) {
          errorCallback(e)
        }
      })
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const [error, setError] = useState<any>(null)
  const [response, setResponse] = useState<CreateBondRequestResponse | null>(null)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(sendBondRequest, [])

  let content
  if (error) {
    content = (
      <span className="error-message" data-testid="bond-request-error-message">
        <FormattedMessage id="bond-request-result-technical-error" />
      </span>
    )
  } else if (!response) {
    content = (
      <div className="loader-container" data-testid="bond-request-loader-container">
        <Loader isVisible />
      </div>
    )
  } else {
    content = (
      <div className="success-container" data-testid="success-container">
        <div className="summary-details">
          <ResultMessage bond={bond} bondRequestResponse={response} />
        </div>
      </div>
    )
  }

  return (
    <div className="bond-request-result" data-testid="bond-request-result">
      {content}
    </div>
  )
}

export default BondRequestResult
