import Bond from '../models/Bond'
import BondType from '../models/BondType'
import IntermediaryType from '../models/IntermediaryType'

export const isBondFinalized = (bond: Bond) => bond.bondStateCode === 'FIN' || bond.bondStateCode === 'ACP'

export const getBondIntermediaryType: (bond: BondType) => IntermediaryType = (bond: BondType) => {
  switch (bond) {
    case 'BID':
    case 'PERFORMANCE':
      return 'TENDERS'
    case 'LIS':
    case 'LOTTO':
    case 'LOTTERY':
    case 'TAX_STAMPS':
    case 'UNA_TANTUM':
    case 'NINE_YEAR':
      return 'TOBACCO'
    default:
      throw new Error(`Unhandled bond type ${bond}`)
  }
}
