import { ErrorDescriptor, ICompany, ManagedError, TECHNICAL_ERROR } from '@gsp/gusto-front-common'

import apiUrls from './apiUrls'
import { authenticatedGet } from './authenticatedFetch'

export const COMPANY_NOT_FOUND: ErrorDescriptor = {
  defaultMessageKey: 'company-search-notfound',
}

const fetchCompanyOptions = {
  responseHandler: (status: number, content: Response) => {
    switch (status) {
      case 200:
        return content.json()
      case 204:
        throw new ManagedError(COMPANY_NOT_FOUND)
      default:
        throw new ManagedError(TECHNICAL_ERROR)
    }
  },
}

export const fetchCompany = (countryCode: string, identifierType: string, identifier: string): Promise<ICompany> =>
  authenticatedGet(
    apiUrls.companySearch(countryCode, identifierType, identifier),
    fetchCompanyOptions
  ) as Promise<ICompany>

export const getCompany = async (companyId: string): Promise<ICompany> =>
  authenticatedGet(apiUrls.getCompany(companyId)) as Promise<ICompany>
