import React from 'react'
import { FormattedMessage } from 'react-intl'
import { ErrorDescriptor, ICompany, Loader } from '@gsp/gusto-front-common'

import { COMPANY_NOT_FOUND } from '../../../../../services/company'

import building from '../../../../../resources/building.png'

const errorMessage = (errorDescriptor: ErrorDescriptor) =>
  errorDescriptor === COMPANY_NOT_FOUND ? 'company-search-notfound' : 'quotation-error'

const getCompanyName = (companyInfo: ICompany) => (companyInfo ? companyInfo.companyName : '')

const getCompanyStreet = (companyInfo: ICompany) => {
  if (companyInfo && companyInfo.address) {
    return companyInfo.address.streetName
  }
  return ''
}

interface ICompanySearchResult {
  loading: boolean
  errorDescriptor: ErrorDescriptor | undefined
  company: ICompany | null
}

const CompanySearchResult = ({ loading, errorDescriptor, company }: ICompanySearchResult) => {
  if (loading) {
    return <Loader isVisible />
  }
  if (errorDescriptor) {
    return (
      <div className="search__error">
        <FormattedMessage id={errorMessage(errorDescriptor)} />
      </div>
    )
  }
  if (company !== null) {
    return (
      <>
        <Loader />
        <div data-testid="company-search-name" className="company-search__preview">
          {getCompanyName(company)}
        </div>
        <div className="company-search__preview">{getCompanyStreet(company)}</div>
        <img src={building} alt="building" />
      </>
    )
  }
  return null
}

export default CompanySearchResult
