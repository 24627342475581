export interface BeneficiaryInformationField {
  keyName: string
  mandatory: boolean
  widthPercentage?: number
  readonly?: boolean
  objectPath?: string
  isCountryDropdown?: boolean
  isDateField?: boolean
}

export interface BeneficiaryInformationBlock {
  titleKey?: string
  fields: BeneficiaryInformationField[]
}

const addressFieldsBlock: BeneficiaryInformationBlock = {
  titleKey: 'principalAddress',
  fields: [
    {
      keyName: 'streetName',
      mandatory: true,
      objectPath: 'principalAddress.streetName',
    },
    {
      keyName: 'streetNumber',
      mandatory: false,
      widthPercentage: 20,
      objectPath: 'principalAddress.streetNumber',
    },
    {
      keyName: 'postCode',
      mandatory: false,
      widthPercentage: 30,
      objectPath: 'principalAddress.postCode',
    },
    {
      keyName: 'stateCode',
      mandatory: true,
      objectPath: 'principalAddress.stateCode',
    },
    {
      keyName: 'town',
      mandatory: true,
      objectPath: 'principalAddress.town',
    },
    {
      keyName: 'countryCode',
      mandatory: true,
      isCountryDropdown: true,
      objectPath: 'principalAddress.countryCode',
    },
  ],
}

const companyFieldsBlock: BeneficiaryInformationBlock = {
  fields: [
    {
      keyName: 'companyId',
      mandatory: true,
      readonly: true,
    },
    {
      keyName: 'companyName',
      mandatory: true,
      readonly: true,
    },
  ],
}

const principalNameFieldBlock: BeneficiaryInformationBlock = {
  fields: [
    {
      keyName: 'principalName',
      mandatory: true,
    },
  ],
}

const principalCodesFields: BeneficiaryInformationField[] = [
  {
    keyName: 'principalFiscalCode',
    mandatory: true,
    readonly: true,
  },
  {
    keyName: 'principalVatNumber',
    mandatory: true,
    readonly: true,
  },
]

const tobaccoInformationParameters: BeneficiaryInformationBlock[] = [
  companyFieldsBlock,
  principalNameFieldBlock,
  {
    fields: principalCodesFields,
  },
  addressFieldsBlock,
]

const contractWithPlaceOfBirthInformationParameter: BeneficiaryInformationBlock[] = [
  companyFieldsBlock,
  principalNameFieldBlock,
  {
    fields: [
      ...principalCodesFields,
      {
        keyName: 'principalPlaceOfBirth',
        mandatory: true,
      },
    ],
  },
  addressFieldsBlock,
]

const principalPrivateAddressBlock: BeneficiaryInformationBlock = {
  titleKey: 'principalPrivateAddress',
  fields: [
    {
      keyName: 'streetName',
      mandatory: true,
      objectPath: 'privateAddress.streetName',
    },
    {
      keyName: 'streetNumber',
      mandatory: false,
      widthPercentage: 20,
      objectPath: 'privateAddress.streetNumber',
    },
    {
      keyName: 'postCode',
      mandatory: false,
      widthPercentage: 30,
      objectPath: 'privateAddress.postCode',
    },
    {
      keyName: 'town',
      mandatory: true,
      objectPath: 'privateAddress.town',
    },
  ],
}

const unaTantumInformationParameters = [
  companyFieldsBlock,
  principalNameFieldBlock,
  {
    fields: [
      ...principalCodesFields,
      {
        keyName: 'principalPlaceOfBirth',
        mandatory: true,
      },
      {
        keyName: 'principalDateOfBirth',
        mandatory: true,
        isDateField: true,
      },
    ],
  },
  addressFieldsBlock,
  principalPrivateAddressBlock,
]

const clientInformationParameters: Record<
  string,
  {
    fields: BeneficiaryInformationBlock[]
  }
> = {
  LOTTO: {
    fields: contractWithPlaceOfBirthInformationParameter,
  },
  LOTTERY: {
    fields: tobaccoInformationParameters,
  },
  NINE_YEAR: {
    fields: contractWithPlaceOfBirthInformationParameter,
  },
  LIS: {
    fields: tobaccoInformationParameters,
  },
  TAX_STAMPS: {
    fields: tobaccoInformationParameters,
  },
  UNA_TANTUM: {
    fields: unaTantumInformationParameters,
  },
}

export default clientInformationParameters
