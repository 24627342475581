import React, { useContext, useEffect, useReducer } from 'react'
import { Col, Row } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import { Input, IRadioGroupOption, NumberInput, RadioGroup, StepComponent, TextArea } from '@gsp/gusto-front-common'
import { FormattedMessage } from 'react-intl'

import { DateTime } from 'luxon'
import { Icon, Popover } from '@myeh/design-system'

import BondContext from '../../../../models/BondContext'
import Project from '../../../../models/Project'

import projectInformationParameters from './projectInformationParameters'
import reducer from './projectInformationReducer'
import SignatureInformation from '../SignatureInformation/SignatureInformation'
import TobacconistQuotationDates from '../TobacconistQuotationDates/TobacconistQuotationDates'

export interface ProjectInformationState extends Project {
  isAmountValid: boolean
  isDateValid: boolean
  authenticationType: string
  digitalSignatureFormat: string
  startDate?: Date | null
  endDate?: Date | null
}

const renewalOptions: IRadioGroupOption[] = [
  {
    key: 'beneficiary-project-bond-renewal-6',
    value: 'HALF_YEARLY',
  },
  {
    key: 'beneficiary-project-bond-renewal-12',
    value: 'YEARLY',
  },
]

const ProjectInformation = ({ setStepCompleted }: StepComponent) => {
  const { bond, setBond } = useContext(BondContext)

  const parameters = projectInformationParameters[bond.bondType]

  const validateAmount = (value: number | null) => !!value

  const [state, dispatch] = useReducer(reducer, {}, () => {
    const state: Record<string, any> = {
      description: (parameters.editableDescription && bond.project?.description) || parameters.defaultDescription,
      isAmountValid: true,
      isDateValid: true,
    }

    if (bond.intermediaryType === 'TENDERS') {
      state.amount = bond.project?.amount ?? bond.principalDebtAmount?.value ?? null
      state.location = bond.project?.location ?? ''
      if (bond.bondType === 'PERFORMANCE') {
        state.bidBondNumber = bond.project?.bidBondNumber ?? ''
        state.renewal = bond.project?.renewal ?? 'HALF_YEARLY'
      }
      state.isAmountValid = validateAmount(state.amount)
    } else {
      state.authenticationType = bond.additional?.authenticationType ?? 'NORMAL'
      state.digitalSignatureFormat = bond.additional?.digitalSignatureFormat ?? 'PES'
      state.startDate = bond.startDate
      state.endDate = bond.endDate
    }
    return state as ProjectInformationState
  })

  useEffect(() => {
    const { isAmountValid, isDateValid, startDate, endDate, authenticationType, digitalSignatureFormat, ...project } =
      state

    if (isAmountValid && isDateValid && project.description) {
      setStepCompleted(true)
    } else {
      setStepCompleted(false)
    }

    if (bond.intermediaryType === 'TENDERS') {
      setBond({
        ...bond,
        project: {
          ...bond.project,
          ...project,
        },
      })
    } else {
      setBond({
        ...bond,
        startDate: startDate as Date,
        endDate: endDate as Date,
        project: {
          ...bond.project,
          ...project,
        },
        additional: {
          ...bond.additional,
          authenticationType,
          digitalSignatureFormat,
        },
      })
    }
  }, [state])

  return (
    <div className="bond-request-form project-information" data-testid="project-information-wrapper">
      <Form className="project-details-form">
        <Row>
          <Col sm={6}>
            <Form.Group>
              {bond.intermediaryType === 'TENDERS' ? (
                <>
                  <NumberInput
                    value={state.amount ? String(state.amount) : ''}
                    changeValue={value => dispatch({ type: 'SET_PROJECT_AMOUNT', payload: { value, validateAmount } })}
                    labelKey={`beneficiary-project-amount-${bond.bondType.toLocaleLowerCase()}`}
                    type="number"
                    step="1000"
                    isRequired={true}
                    defaultError={!state.isAmountValid ? 'invalid-amount' : ''}
                  />
                  <Input
                    value={state.location!}
                    changeValue={value => dispatch({ type: 'SET_PROJECT_FIELD', payload: { key: 'location', value } })}
                    labelKey="beneficiary-project-location"
                    forceUppercase
                  />
                </>
              ) : (
                <TobacconistQuotationDates
                  oldDuration={
                    DateTime.fromJSDate(bond.endDate as Date).diff(DateTime.fromJSDate(bond.startDate as Date), 'days')
                      .days
                  }
                  setDates={(startDate, endDate, isDateValid) =>
                    dispatch({
                      type: 'SET_DATES',
                      payload: {
                        startDate,
                        endDate,
                        isDateValid,
                      },
                    })
                  }
                />
              )}
              <NumberInput
                value={String(bond.amount)}
                labelKey="beneficiary-project-bond-amount"
                disabled={true}
                type="number"
              />
            </Form.Group>
            {bond && bond.bondType.toUpperCase() === 'PERFORMANCE' && (
              <Form.Group>
                <Input
                  value={state.bidBondNumber!}
                  changeValue={value =>
                    dispatch({ type: 'SET_PROJECT_FIELD', payload: { key: 'bidBondNumber', value } })
                  }
                  labelKey="beneficiary-project-bond-number"
                  forceUppercase
                />
                <div className="beneficiary-project-bond-renewal">
                  <RadioGroup
                    options={renewalOptions}
                    titleKey="beneficiary-project-bond-renewal"
                    name="renewal"
                    disabled={false}
                    selectedValue={state.renewal!}
                    onChange={value => dispatch({ type: 'SET_PROJECT_FIELD', payload: { key: 'renewal', value } })}
                    direction="column"
                    labelSize="small"
                  />
                </div>
              </Form.Group>
            )}
          </Col>
          <Col sm={6} className="description">
            <TextArea
              value={state.description}
              changeValue={value => dispatch({ type: 'SET_PROJECT_FIELD', payload: { key: 'description', value } })}
              labelKey={
                bond.intermediaryType === 'TENDERS' ? 'beneficiary-project-description' : 'beneficiary-bond-description'
              }
              maxLength={500}
              appendix={
                parameters.displayTooltip ? (
                  <Popover trigger="hover">
                    <Popover.Toggle
                      aria-label="More informations"
                      style={{
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Icon name="info-circle-o" />
                    </Popover.Toggle>
                    <Popover.Content css>
                      <FormattedMessage id={`beneficiary-bond-description-tooltip-${bond.bondType.toLowerCase()}`} />
                    </Popover.Content>
                  </Popover>
                ) : undefined
              }
              disabled={!parameters.editableDescription}
              forceUppercase
              isRequired
            />
          </Col>
        </Row>
        {bond.intermediaryType === 'TOBACCO' && (
          <SignatureInformation
            authenticationType={state.authenticationType!}
            digitalSignatureFormat={state.digitalSignatureFormat!}
            onAuthenticationTypeChange={value =>
              dispatch({ type: 'SET_PROJECT_FIELD', payload: { key: 'authenticationType', value } })
            }
            onDigitalSignatureFormatChange={value =>
              dispatch({ type: 'SET_PROJECT_FIELD', payload: { key: 'digitalSignatureFormat', value } })
            }
          />
        )}
      </Form>
    </div>
  )
}

export default ProjectInformation
